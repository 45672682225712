import React, { useState } from 'react';

import PropTypes from 'prop-types';

const Context = React.createContext([{}, () => {}]);

const AppProvider = ({ children, user }) => {
  const [appState, setAppState] = useState({ user });

  return <Context.Provider value={{ appState, setAppState }}>{children}</Context.Provider>;
};

AppProvider.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object
};

export { Context, AppProvider };
