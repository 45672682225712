import { openDB } from 'idb';
const version = 1;
const dbName = '_classified_';
const storeName = 'vap';
let dbPromise = null;

if (typeof window !== 'undefined') {
  dbPromise = openDB(dbName, version, {
    upgrade (db, oldVersion, newVersion) {
      if (oldVersion > 0 && oldVersion < version && oldVersion < newVersion) {
        db.deleteObjectStore(storeName);
      }
      db.createObjectStore(storeName);
    }
  });
}

const isExpiryTimestampKey = (key) => {
  // timestamp keys have following format : '__<key>ExpiryTimeStamp'
  if (key.match(/__\w*ExpiryTimeStamp/g)) {
    return true;
  }
  return false;
};

const deleteKey = async (key) => {
  const db = await dbPromise;
  if (isExpiryTimestampKey(key)) {
    // if trying to delete the expiryTimeStamp key, delete the original key as well.
    db.delete(storeName, key);
    db.delete(storeName, key.substring(2, key.length - 'ExpiryTimeStamp'.length));
  } else {
    // if trying to delete the original key, delete the expiryTimeStamp key as well.
    db.delete(storeName, key);
    db.delete(storeName, `__${key}ExpiryTimeStamp`);
  }
};

const removeExpired = async () => {
  const db = await dbPromise;
  const keys = await db.getAllKeys(storeName);
  keys.map(async (each) => {
    if (isExpiryTimestampKey(each)) {
      const val = await db.get(storeName, each);
      if (Date.now() > val) {
        deleteKey(each);
      }
    }
  });
};

const storage = {
  async get (key) {
    const db = await dbPromise;
    const timeStampKey = `__${key}ExpiryTimeStamp`;
    const value = await db.get(storeName, timeStampKey);
    if (value && typeof value !== 'undefined') {
      if (value > Date.now()) {
        return db.get(storeName, key);
      } else {
        deleteKey(key);
        return null;
      }
    }
    return db.get(storeName, key);
  },
  async set (key, val, time) {
    const db = await dbPromise;
    const promises = [];
    promises.push(db.put(storeName, val, key));
    if (time) {
      promises.push(db.put(storeName, Date.now() + time, `__${key}ExpiryTimeStamp`));
    }
    return new Promise(() => {
      Promise.all(promises)
        .then(() => {
          // Once the keys are set, remove any expired keys.
          removeExpired();
        })
        .catch((e) => {
          console.log('IDBCache: Setting keys failed', e);
        });
    });
  },
  async delete (key) {
    deleteKey(key);
  },
  async clear () {
    return (await dbPromise).clear(storeName);
  },
  isSupported () {
    if (typeof window === 'undefined') return false;
    return 'indexedDB' in window;
  }
};

export default storage;
