import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SVG = styled.svg`
  width: 22px;
  fill: #ffffff;
`;

const ArrowIcon = props => {
  const { onClick, ...rest } = props;
  return (
    <SVG onClick={onClick} viewBox="0 0 72 72" {...rest}>
      <path d="M68.5,30.87H18.94L41.69,8.1,36,2.41,3.5,34.94,36,67.47l5.69-5.69L18.94,39H68.5Z" />
    </SVG>
  );
};

ArrowIcon.propTypes = {
  onClick: PropTypes.func
};
export default ArrowIcon;
