export default {
  Primary: '#0083CA',
  Secondary: '#0FAA14',
  Tertiary: '#CA0900',
  Quaternary: '#FE9500',
  Pentanary: '#41A7AD',
  Quinary: '#5B7194',
  TextAlpha: '#333',
  TextBeta: '#666',
  TextGamma: '#999',
  TextDelta: '#5b7194',
  Success: '#E3FEE0',
  Failure: '#F8655E',
  Error: '#F22727',
  Gold: '#dac251',
  Spotlight: '#7600d0',
  Neutral: {
    100: '#D8D8D8',
    200: '#DDD',
    300: '#EEE',
    400: '#F2F2F2',
    500: '#F8F8F8',
    600: '#FAFAFA'
  },
  TertiaryGradient: 'linear-gradient(135deg, #F1FDFD, #F1F7FB)',
  PrimaryGradient: 'linear-gradient(135deg, #00CFD2 0%, #0060A7 100%)',
  SecondaryGradient: 'linear-gradient(135deg, #E9F5E3 0%, #CEE7FC 100%)',
  PostAdbtn: 'linear-gradient(119deg, rgba(255,172,1,0.54) 0%, #FFE700 100%)',
  CrossCatGradient:
    'linear-gradient(90deg, rgba(194, 229, 156, 0.32) 0%, rgba(100, 179, 244, 0.32) 89%);',
  TopFeatureGradient: 'linear-gradient(135deg, #F2FDFD 0%, #F2F7FB 100%)',
  NewTagGradient: 'linear-gradient(-45deg, #FE7802 0%, #F3452D 49%, #FE7802 100%)',
  gold: 'linear-gradient(47deg, #EBCD4C 0%, #E9A237 100%)',
  platinum: 'linear-gradient(47deg, #F89366 0%, #DA595C 100%)',
  Chip: '#0083CA'
};
