import styled from 'styled-components';

const CheckboxStyle = styled.input`
  appearance: none;
  z-index: -1;
  left: -10px;
  top: -8px;
  width: 40px;
  height: 40px;
  background-color: rgba(153, 153, 153, 1);
  box-shadow: none;
  opacity: 0;
  transform: scale(1);
  transition: opacity 0.3s, transform 0.2s;
  will-change: opacity, transform;
  &:checked,
  &:indeterminate {
    background-color: rgba(0, 131, 202, 1);
    + span {
      &:before {
        border-color: ${({ theme }) => theme.colors.Primary};
        background-color: ${({ theme }) => theme.colors.Primary};
      }
    }
    + span {
      &:after {
        border-color: rgba(255, 255, 255);
      }
    }
  }
  &:indeterminate {
    + span {
      &:after {
        border-left: none;
        transform: translate(4px, 3px);
      }
    }
  }
  &:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
    + span {
      &:before {
        border-color: rgba(0, 131, 202, 1);
      }
    }
  }
  &:disabled {
    opacity: 0;
    span {
      color: rgba(0, 0, 0, 0.38);
      cursor: initial;
      &:before {
        border-color: currentColor;
      }
    }
  }
  &:checked:active {
    + span {
      &:before {
        border-color: transparent;
        background-color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  &:checked:disabled,
  &:indeterminate:disabled {
    + span {
      &:before {
        border-color: transparent;
        background-color: currentColor;
      }
    }
  }
`;

export default CheckboxStyle;
