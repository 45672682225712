import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ImageView from '../ImageView';
import CarouselBtn from '../CarouselBtn';

const ImgCarousel = styled.div`
  height: ${props => props.carouselHeight + 'px'};
`;

const ImgCarouselContainer = styled.div`
  transition: transform 1s;
`;

const Img = styled(ImageView)`
  vertical-align: top;
  display: inline-block;
`;

const CarouselLeftBtn = styled(CarouselBtn)`
  left: 20px;
  top: calc(50% - 24px);
`;

const CarouselRightBtn = styled(CarouselBtn)`
  right: 20px;
  top: calc(50% - 24px);
`;

class ImageCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStyle: {}
    };
    this.slideCounter = 0;
  }

  slideImg = (e, direction) => {
    e.stopPropagation();
    direction === 'right' ? this.slideCounter++ : this.slideCounter--;
    this.setState({
      currentStyle: {
        transform: 'translateX(-' + this.slideCounter * 100 + '%)'
      }
    });
  };

  render() {
    const { images, alt, onClick, carouselHeight, ...rest } = this.props;
    return (
      <ImgCarousel onClick={onClick} carouselHeight={carouselHeight} {...rest}>
        <div className="pR w100 h100 oH wsN">
          <ImgCarouselContainer className="pR h100" style={this.state.currentStyle}>
            {images.map((img, index) => {
              return (
                <Img
                  className="w100 h100 taC"
                  alt={alt ? (index === 0 ? `${alt}` : `${alt}(${index})`) : ''}
                  key={index}
                  url={img}
                  maxHeight={carouselHeight}
                  trigger={index === 0 ? true : false}
                  blur
                />
              );
            })}
          </ImgCarouselContainer>
          {this.slideCounter !== 0 && (
            <CarouselLeftBtn
              type="left"
              onClick={e => {
                this.slideImg(e, 'left');
              }}
            />
          )}
          {this.slideCounter !== images.length - 1 && (
            <CarouselRightBtn
              type="right"
              onClick={e => {
                this.slideImg(e, 'right');
              }}
            />
          )}
        </div>
      </ImgCarousel>
    );
  }
}

ImageCarousel.propTypes = {
  onClick: PropTypes.func,
  carouselHeight: PropTypes.number,
  images: PropTypes.array.isRequired,
  alt: PropTypes.string
};

ImageCarousel.defaultProps = {
  alt: '',
  carouselHeight: 400
};

export default ImageCarousel;
