import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import config from '../../../config';
const { STATIC_FILE_PATH } = config;

const MetaTags = ({ meta: { title, description } }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="shortcut icon" href={`/${STATIC_FILE_PATH}/favicon.ico`} type="image/x-icon" />
    </Helmet>
  );
};

MetaTags.propTypes = {
  meta: PropTypes.object.isRequired
};

export default MetaTags;
