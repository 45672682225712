import React, { useState } from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { common } from '../../../gql/common';
import { useGa } from '../../../hooks';

const Header = loadable(p => import(`@quikr/ui/theme/${p.host}/components/Header`));
const Hamburger = loadable(() => import('../Hamburger'), { ssr: false });

const HeaderWrapper = props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [links, setLinks] = useState({
    called: false,
    links: [],
    error: {}
  });

  const {
    data,
    data: { loading },
    host,
    hideHamburger
  } = props;

  if (loading) return null;
  const { isMobile, isAndroid, category, downloadApp, user, city = {} } = data;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const loggedIn = Boolean(user);
  return (
    <>
      <Header
        isMobile={isMobile}
        isAndroid={isAndroid}
        downloadApp={downloadApp}
        pageType="VAP"
        category={category}
        toggleMenu={toggleMenu}
        loggedIn={loggedIn}
        userObj={user || {}}
        city={city}
        host={host}
        useGa={useGa}
        hideHamburger={hideHamburger}
      />
      {isMenuOpen && (
        <Hamburger
          loggedIn={loggedIn}
          userObj={user || {}}
          isMobile={isMobile}
          toggleMenu={toggleMenu}
          host={host}
          show={isMenuOpen}
          links={links}
          handleLinks={setLinks}
        />
      )}
    </>
  );
};

HeaderWrapper.propTypes = {
  data: PropTypes.object,
  host: PropTypes.string,
  hideHamburger: PropTypes.bool
};

export default graphql(common)(HeaderWrapper);
