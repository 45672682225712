import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
const FlexColumn = styled.div`
  flex-basis: ${props => ((props.md / 12) * 100).toFixed(2)}%;
  max-width: ${props => ((props.md / 12) * 100).toFixed(2)}%;
  padding-left: ${props => props.gutterWidth}px;
  padding-right: ${props => props.gutterWidth}px;
  ${({ hiddenXs }) =>
    hiddenXs &&
    css`
      @media (max-width: 767px) {
        display: none;
      }
    `};
  ${({ hiddenMd }) =>
    hiddenMd &&
    css`
      @media (min-width: 1200px) and (max-width: 1899px) {
        display: none;
      }
    `};
  ${({ hiddenSm }) =>
    hiddenSm &&
    css`
      @media (min-width: 768px) and (max-width: 1199px) {
        display: none;
      }
    `};
  ${({ sm }) =>
    css`
      @media (min-width: 768px) and (max-width: 1199px) {
        flex-basis: ${props => ((props.sm / 12) * 100).toFixed(2)}%;
        max-width: ${props => ((props.sm / 12) * 100).toFixed(2)}%;
      }
    `};
  @media (max-width: 767px) {
    flex-basis: ${props => ((props.xs / 12) * 100).toFixed(2)}%;
    max-width: ${props => ((props.xs / 12) * 100).toFixed(2)}%;
  }
`;

const Column = ({ children, md, sm, xs, hiddenXs, hiddenSm, hiddenMd, gutterWidth, ...rest }) => {
  return (
    <FlexColumn
      md={md}
      xs={xs}
      sm={sm}
      hiddenXs={hiddenXs}
      gutterWidth={gutterWidth}
      hiddenSm={hiddenSm}
      hiddenMd={hiddenMd}
      {...rest}
    >
      {children}
    </FlexColumn>
  );
};

Column.propTypes = {
  children: PropTypes.any,
  md: PropTypes.number.isRequired,
  xs: PropTypes.number.isRequired,
  sm: PropTypes.number,
  class: PropTypes.string,
  hiddenSm: PropTypes.bool,
  hiddenXs: PropTypes.bool,
  hiddenMd: PropTypes.bool,
  gutterWidth: PropTypes.number
};
Column.defaultProps = {
  md: 12,
  xs: 12,
  gutterWidth: 12
};

export default Column;
