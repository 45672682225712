import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Loader from './Loader';
import Link from '../Link';

const Btn = styled.button`
  height: 32px;
  padding: 0 20px;
  border-radius: 4px;
  background: #fff;
  &:focus{
    outline: none;
  }
  ${({ btnType }) => {
    switch (btnType) {
      case 'primary':
        return css`
          color: white;
          background: ${({ theme }) => theme.colors.PrimaryGradient};
          border: none;
          box-shadow: 0 2px 4px 0 rgba(171, 171, 171, 0.5);
        `;
      case 'postAd':
        return css`
          color: ${({ theme }) => theme.colors.TextAlpha};
          background: ${({ theme }) => theme.colors.PostAdbtn};
          border: 1.5px solid #f4a04e;
        `;
      case 'secondary':
        return css`
          color: ${({ theme }) => theme.colors.Primary};
          border: 1.5px solid ${({ theme }) => theme.colors.Primary};
          background: none;
        `;
      case 'login':
        return css`
          color: ${({ theme }) => theme.colors.TextAlpha};
          border: 1.5px solid ${({ theme }) => theme.colors.Primary};
          box-shadow: 0 2px 4px 0 rgba(171, 171, 171, 0.5);
          &:hover {
            background: ${({ theme }) => theme.colors.Neutral[500]};
            box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
            border-radius: 2px;
          }
        `;
      case 'danger':
        return css`
          color: ${({ theme }) => theme.colors.Tertiary};
          border: 1.5px solid ${({ theme }) => theme.colors.Tertiary};
          &:hover {
            background: ${({ theme }) => theme.colors.Tertiary};
            color: #fff;
          }
        `;
      case 'disabled':
        return css`
          color: ${({ theme }) => theme.colors.TextGamma};
          background: ${({ theme }) => theme.colors.Neutral[200]};
          border: none;
          cursor: not-allowed;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
          &:hover {
            background: ${({ theme }) => theme.colors.Neutral[200]};
            color: ${({ theme }) => theme.colors.TextGamma};
          }
        `;
      default:
        return css`
          color: ${({ theme }) => theme.colors.Primary};
          border: 1.5px solid ${({ theme }) => theme.colors.Primary};
          box-shadow: 0 2px 4px 0 rgba(171, 171, 171, 0.5);
          &:hover {
            background: ${({ theme }) => theme.colors.Primary};
            color: #fff;
          }
        `;
    }
  }}
  ${({ size }) =>
    size === 'lg' &&
    css`
      height: 52px;
    `}

  ${({ loading }) =>
    loading &&
    css`
      align-items: center;
      pointer-events: none;
    `}
  ${({ href }) =>
    href &&
    css`
      border: none;
      box-shadow: none;
      &:hover {
        text-decoration: none;
      }
    `}
  ${({ shadow }) =>
    !shadow &&
    css`
      box-shadow: none;
    `}
`;

const Button = ({ type, onClick, children, disabled, loading, href, size, className, ...rest }) => {
  if (href) {
    return (
      <Btn
        as={Link}
        onClick={onClick}
        href={href}
        size={size}
        className={`${className} ${size === 'lg' ? 'bt1' : 'bt2'}`}
        {...rest}
      >
        {children}
      </Btn>
    );
  } else {
    return (
      <Btn
        type={type}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        size={size}
        className={`${className} ${size === 'lg' ? 'bt1' : 'bt2'}`}
        {...rest}
      >
        {loading ? <Loader /> : children}
      </Btn>
    );
  }
};

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'lg']),
  href: PropTypes.string,
  disabled: PropTypes.bool,
  postAd: PropTypes.bool,
  loading: PropTypes.bool,
  login: PropTypes.string,
  btnType: PropTypes.string,
  className: PropTypes.string,
  shadow: PropTypes.bool
};

Button.defaultProps = {
  type: 'button',
  size: 'lg',
  disabled: false,
  btnType: 'default',
  href: '',
  shadow: true
};

export default Button;
