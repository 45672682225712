import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '../Typography';
import LazyImg from '../LazyImg';

const Highlight = styled.ul`
  background: ${({ theme }) => theme.colors.TertiaryGradient};
  padding: 10px;
  margin: 15px 0px 0px;
  border-radius: 6px;
  height: 80px;
  @media (min-width: 767px) {
    max-width: 378px;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 80px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #f1fdfd transparent;
  }
  @media (min-width: 370px) and (max-width: 767px) {
    height: 100px;
  }
  @media (max-width: 767px) {
    &:before {
      left: 80%;
      border-color: transparent transparent #f1f7fb transparent;
    }
    width: 100%;
  }
`;
const Step = styled.li`
  @media (max-width: 767px) {
    padding: 2px;
  }
`;
const Icon = styled(LazyImg)`
  height: 38px;
  width: auto;
  @media (max-width: 767px) and (min-width: 369px) {
    height: 50px;
  }
`;
const Label = styled(Typography)`
  color: #41a7ad;
  margin-top: 8px;
`;
const Highlights = ({ data }) => {
  if (data) {
    return (
      <Highlight className="dF jcSA pR w100">
        {data.map((devData, i) => (
          <Step className="dFA fdC" key={i}>
            <Icon src={devData.icon} />
            <Label tagName="label" modifiers={['bt3']}>
              {devData.step}
            </Label>
          </Step>
        ))}
      </Highlight>
    );
  } else {
    return null;
  }
};

Highlights.propTypes = {
  data: PropTypes.array
};

export default Highlights;
