import React, { useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';

import { apiWrapper } from '@quikr/ui/helpers';
import { getTokens } from '../gql/vap';
import indexedDB from '../helpers/storage';
import { useAppContext } from '../hooks';

const CUSTOM_AD_KEY = '__niro_generic';

const TOKEN_KEY = 'vapinfo';

const NiroContext = React.createContext([{}, () => {}]);

const NiroProvider = props => {
  const [niro, setNiro] = useState({ offerData: { storedResponse: false } });
  const { appState: { user } = {} } = useAppContext();
  const userid = user?.user?.id;

  const client = useApolloClient();
  const { tokens = {} } =
    client.readQuery({
      query: getTokens(TOKEN_KEY)
    }) || {};
  const { action } = tokens[TOKEN_KEY];

  const getAndSetNiroOffer = async () => {
    if (!userid) return;
    if (niro.offerData.storedResponse) return;
    const data = indexedDB && (await indexedDB.get(CUSTOM_AD_KEY));
    apiWrapper({
      // eslint-disable-next-line no-undef
      url: `${__WP_CLIENT_GQL_ENDPOINT}/u/niro/generic-offer`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        authorization: action
      }
    }).then(response => {
      setNiro(prev => ({ ...prev, offerData: { ...response, storedResponse: false } }));
      // set for 1 day in ms
      indexedDB.set(CUSTOM_AD_KEY, { ...data, [userid]: response }, 24 * 60 * 60 * 1000);
    });
  };

  useEffect(() => {
    if (!userid) return;
    const getIdbData = async () => {
      const data = indexedDB && (await indexedDB.get(CUSTOM_AD_KEY));
      if (data && data[userid]) {
        setNiro(prev => ({
          ...prev,
          offerData: { ...data[userid], storedResponse: true }
        }));
      }
    };
    getIdbData();
  }, [userid]);

  return (
    <NiroContext.Provider value={[niro, getAndSetNiroOffer]}>{props.children}</NiroContext.Provider>
  );
};

NiroProvider.propTypes = {
  children: PropTypes.node
};

export { NiroContext, NiroProvider };
