import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '../Typography';
import { convertPriceToIndFormat } from '../../helpers/common';

const PriceDiv = styled.div`
  margin: 20px 0px;
  @media (max-width: 767px) {
    margin: 0px;
    flex-direction: column;
  }
`;
const Price = styled(Typography)`
  color: ${({ theme }) => theme.colors.Primary};
  margin-right: 10px;
`;
const Discount = styled(Typography)`
  color: ${({ theme }) => theme.colors.TextBeta};
  margin-right: 15px;
  span {
    text-decoration: line-through;
  }
  p {
    color: ${({ theme }) => theme.colors.Quaternary};
    margin-left: 5px;
  }
`;
const Alert = styled.label`
  color: ${({ theme }) => theme.colors.Primary};
  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;
const AskPrice = styled(Typography)`
  color: ${({ theme }) => theme.colors.TextAlpha};
`;

const PriceDetail = forwardRef((props, ref) => {
  const { price, mrp, priceAlert, askForPrice, ...rest } = props;
  const totalDiscount = parseFloat(((mrp - price) * 100) / mrp).toFixed(2);

  return (
    <PriceDiv className="dFA" ref={ref} {...rest}>
      {price ? (
        <Price tagName="label" modifiers={['bold', 'h1']}>
          <span>₹</span>
          {price}
        </Price>
      ) : (
        askForPrice && (
          <AskPrice tagName="label" modifiers={['bold', 'h2']} className="dFA">
            Ask for Price
          </AskPrice>
        )
      )}
      {mrp && (
        <Discount tagName="label" modifiers={['medium', 'bt2']} className="dFA">
          <span>₹ {convertPriceToIndFormat(mrp)}</span>
          <p>({totalDiscount})% OFF</p>
        </Discount>
      )}
      {priceAlert && <Alert>Set Price Alert</Alert>}
    </PriceDiv>
  );
});

PriceDetail.displayName = 'PriceDetailComp';

PriceDetail.propTypes = {
  price: PropTypes.any,
  mrp: PropTypes.number,
  priceAlert: PropTypes.bool,
  askForPrice: PropTypes.bool
};
export default PriceDetail;
