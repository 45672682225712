import { onError } from '@apollo/client/link/error';

/**
 * Error handling function for apollo client
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, \nLocation: ${JSON.stringify(
          locations
        )}, \nPath: ${JSON.stringify(path)}, \nextensions: ${JSON.stringify(
          extensions,
          null,
          '  '
        )}`
      )
    );
  }
  if (networkError) console.log(`[Network error]: ${JSON.stringify(networkError, null, '  ')}`);
});

export default errorLink;
