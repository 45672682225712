import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ViewMore from '../ViewMore';
import Typography from '../Typography';

const Ul = styled.ul`
  border-bottom: 1px solid ${({ theme }) => theme.colors.Neutral[200]};
  margin: 0px auto;
  padding-top: 7px;
  display: flex;
  background: #fff;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${({ tabsPos }) =>
    tabsPos
      ? css`
          position: sticky;
          position: -webkit-sticky;
        `
      : css`
          position: relative;
        `}
  ${({ top }) =>
    css`
      top: ${top || 92}px;
    `}
  z-index: 1;
  background: #fff;
  @media (max-width: 767px) {
    display: none;
  }
  &:before {
    ${({ left, width }) =>
      css`
        content: '';
        position: absolute;
        z-index: 10;
        bottom: 0;
        height: 3px;
        background: ${({ theme }) => theme.colors.Primary};
        display: block;
        left: ${left}px;
        transition: left 0.2s ease;
        width: ${width}px;
      `}
  }
`;

const Li = styled(Typography)`
  padding: 13px 20px;
  transition: all 0.3s ease;
  color: ${({ theme }) => theme.colors.TextBeta};
  ${({ classType }) =>
    classType === 'Active' &&
    css`
      color: ${({ theme }) => theme.colors.TextAlpha};
      font-weight: 500;
      border: none;
      padding-bottom: 10px;
    `}
`;

const Div = styled.div`
  line-height: 22px;
`;

const ViewM = styled(ViewMore)`
  display: initial !important;
  left: 4px;
`;

class TabsSlide extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      leftSpacing: 0,
      width: null,
      activeIndex: 0
    };
  }

  componentDidMount () {
    const { tabsPosition } = this.props;
    const tabs = this.props.data.map(obj => obj.id);
    this.setState({ activeTab: tabs[0] });
    const width = document.getElementById(0).offsetWidth;
    this.setState({
      width
    });
    if (tabsPosition) {
      window.addEventListener('scroll', () => this.onScroll(tabs));
    }
  }

  checkVisible = elm => {
    const scroll = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
    const { offsetHeight, offsetTop } = elm;
    if (offsetTop - 82 <= scroll && offsetTop + offsetHeight - 82 > scroll) {
      return true;
    } else {
      return false;
    }
  };

  onScroll = data => {
    const { activeIndex } = this.state;
    for (let i = 0; i < data.length; i++) {
      if (this.checkVisible(document.getElementById(data[i]))) {
        this.getPositionOfElement(data[i], i);
      }
    }
    const tab = document.getElementById(activeIndex);
    if (!this.checkVisible(tab)) {
      // tab.scrollIntoView();
    }
  };

  getPositionOfElement = (id, index) => {
    const elmnt = document.getElementById(index);
    this.setState({
      leftSpacing: elmnt.offsetLeft,
      width: elmnt.offsetWidth,
      activeTab: id,
      activeIndex: index
    });
  };

  onTabClick = (id, index) => {
    if (document) {
      const elmnt = document.getElementById(id);
      elmnt.scrollIntoView();
      if (this.props.tabsPosition) {
        window.scrollBy(0, this.props.positionY ? -this.props.positionY : -70);
      }
      this.getPositionOfElement(id, index);
    }
  };

  displayTab = ({ type, title, heading, component, text, id, maxLength }, index) => {
    const { activeTab } = this.state;
    switch (type) {
      case 'paragraph':
        return (
          <Div
            id={id}
            key={index}
            className={activeTab === id ? 'currentSlide mob-border' : 'mob-border'}
          >
            <Typography tagName="h2" modifiers="h2" className="vap__subtitle">
              {heading === undefined ? title : heading}
            </Typography>
            <Div>
              <ViewM className="pR" content={text} maxLength={maxLength} />
            </Div>
          </Div>
        );
      case 'component':
        return (
          <Div
            id={id}
            key={index}
            className={activeTab === id ? 'currentSlide mob-border' : 'mob-border'}
          >
            <Typography tagName="h2" modifiers="h2" className="vap__subtitle">
              {heading === undefined ? title : heading}
            </Typography>
            {component}
          </Div>
        );
    }
  };

  componentWillUnmount () {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  render () {
    const { onTabClick, props, state, displayTab } = this;
    const { data, tabsPosition, positionY, top, ...rest } = props;
    const { activeTab, leftSpacing, width } = state;
    return (
      <Div {...rest}>
        <Ul
          className="oxA w100 wsN zi1"
          tabsPos={tabsPosition}
          id="navTab"
          left={leftSpacing}
          width={width}
          top={top}
        >
          {data.map(({ title, id }, index) => (
            <Li
              tagName="li"
              modifiers="h3"
              className="cP taL"
              id={index}
              key={index}
              classType={activeTab === id ? 'Active' : ''}
              onClick={() => onTabClick(id, index)}
              {...props}
            >
              {title}
            </Li>
          ))}
        </Ul>
        <Div className={'tabsSlideContainer'}>
          {data.map((obj, index) => displayTab(obj, index))}
        </Div>
      </Div>
    );
  }
}

TabsSlide.propTypes = {
  data: PropTypes.array.isRequired,
  tabsPosition: PropTypes.bool,
  positionY: PropTypes.number,
  className: PropTypes.string,
  top: PropTypes.number
};

TabsSlide.defaultProps = {
  tabsPosition: false,
  top: 90
};

export default TabsSlide;
