import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { replaceUnderScoreWithSpace } from '../../helpers/common';
import ViewMore from '../ViewMore';
import Typography from '../Typography';

const Ul = styled.ul`
  @media screen and (min-width: 768px) {
    padding: 0;
  }
`;
const Li = styled.li`
  padding: 10px 0px;
  ${({ showDivider }) =>
    showDivider
      ? css`
          width: 44%;
          :nth-of-type(odd) {
            &:after {
              content: '';
              color: ${({ theme }) => theme.colors.Neutral[100]};
              margin-left: 10px;
              height: 100%;
              width: 1px;
              background: ${({ theme }) => theme.colors.Neutral[100]};
              position: absolute;
              top: 0px;
              right: -14%;
            }
          }
          @media (max-width: 767px) {
            width: 100%;
            justify-content: space-between;
            :nth-of-type(odd) {
              &:after {
                display: none;
              }
            }
          }
        `
      : css`
          width: 100%;
          padding: 10px;
        `}
`;
const Label = styled.label`
  color: ${({ theme }) => theme.colors.TextBeta};
  flex: 0 0 50%;
`;

const ViewData = styled.span`
  word-break: break-all;
  margin: 10px auto 0px;
  color: ${({ theme }) => theme.colors.Primary};
  @media screen and (min-width: 768px) {
    margin-top: 20px;
  }
`;

const DataInfo = styled(Typography)`
  color: ${({ theme }) => theme.colors.TextAlpha};
  word-break: break-all;
  ${({ suffix, theme }) =>
    suffix &&
    css`
      &:after {
        content: ' ${suffix}';
        color: ${theme.colors.TextGamma};
        font-size: ${theme.typeScale.Mobile.bodyText3};
        @media screen and (min-width: 768px) {
          font-size: ${theme.typeScale.Desktop.bodyText3};
        }
      }
  `}
`;

class Table extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      maxData: props.maxLength
    };
  }

  viewAll = (len) => {
    this.setState({
      maxData: len
    });
  };

  componentDidUpdate (prevProps, prevState) {
    if (this.props.maxLength !== prevProps.maxLength) {
      this.setState({
        maxData: this.props.maxLength
      });
    }
  }

  viewMore = (title) => {
    return (
      <ViewData
        className="ttC cP dF w100 jcC"
        onClick={
          title === 'View More'
            ? () => this.viewAll(this.props.data.length)
            : () => this.viewAll(this.props.maxLength)
        }
      >
        {title}
      </ViewData>
    );
  };

  render () {
    const { data, showDivider, showViewMoreBtn } = this.props;
    const { maxData } = this.state;
    const maxLengthRender = maxData || (data && data.length);
    const checkBtn = maxData < data.length ? 'View More' : 'View Less';
    if (data) {
      return (
        <>
          <Ul className="dF fwW jcSB">
            {data.map((info, i) => {
              if (i + 1 <= maxLengthRender) {
                return (
                  <Li className="dF pR" key={i} showDivider={showDivider}>
                    <Label>{replaceUnderScoreWithSpace(info.key)}</Label>
                    <DataInfo
                      className="ttC"
                      tagName="span"
                      modifiers="medium"
                      suffix={info.suffix}
                    >
                      <ViewMore content={info.value} maxLength={36} />
                    </DataInfo>
                  </Li>
                );
              } else {
                return null;
              }
            })}
          </Ul>
          {showViewMoreBtn ? this.viewMore(checkBtn) : ''}
        </>
      );
    } else {
      return null;
    }
  }
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  maxLength: PropTypes.number,
  showDivider: PropTypes.bool,
  showViewMoreBtn: PropTypes.bool
};

Table.defaultProps = {
  showDivider: true,
  showViewMoreBtn: false
};

export default Table;
