import React from 'react';
import PropTypes from 'prop-types';
import VapSecondaryAction from '../VapSecondaryAction';
import ShareIcon from '../VapSecondaryAction/ShareIcon';

const Share = props => {
  const { title } = props;
  const shareClick = e => {
    const href = window.location.href;
    if (navigator.share) {
      navigator.share({
        text: title,
        url: href
      });
    } else {
      window.open(`https://api.whatsapp.com/send?text=${title} ${href}`);
    }
  };
  return (
    <VapSecondaryAction
      onClick={e => {
        shareClick(e);
      }}
    >
      <ShareIcon />
    </VapSecondaryAction>
  );
};

Share.propTypes = {
  title: PropTypes.string
};

export default Share;
