import config from '../../config';

export const BASE_URL = 'https://www.quikr.com/';
export const LOGIN_URL = 'https://www.quikr.com/SignIn';
export const MYQUIKR_URL = 'https://www.quikr.com/MyQuikr';
export const DEFAULT_IMAGE = `${config.IMAGE_CDN_DOMAIN}/public/core/default_images/default-340x270.png`;
export const POST_AD_URL = 'https://www.quikr.com/post-classifieds-ads/select-category/';
export const hamburgerExtraLinks = [
  {
    href: 'https://www.quikr.com/Business?action=businessPage',
    name: 'For Businesses',
    icon: 'ic_for_business',
    key: 'for_business'
  },
  {
    href: 'https://www.quikr.com/deals/',
    name: 'Deals & Offers',
    icon: 'ic_deals_offer',
    key: 'deals_and_offers'
  },
  {
    href: 'http://www.quikr.com/app',
    name: 'Download our App',
    icon: 'ic_download_app',
    key: 'download_app'
  }
];

export const hamburgerAccountLinks = [
  {
    href: 'https://www.quikr.com/MyQuikr?action=activeads',
    name: 'My Ads',
    subtext: 'Ads posted by you',
    icon: 'ic_my_ads',
    key: 'my_ads'
  },
  {
    href: 'https://www.quikr.com/MyQuikr?action=mychats',
    name: 'My Chats',
    subtext: 'Chats, Alerts, Notifications, Etc',
    icon: 'ic_chat',
    key: 'my_chat'
  },
  {
    href: 'https://www.quikr.com/Escrow/MyOrders?user=buyer&tabId=-1&tabName=Escrow',
    name: 'Orders and Payments',
    subtext: 'Status of your orders and payments',
    icon: 'ic_orders_payments',
    key: 'order_payments'
  }
];
