import React from 'react';
import PropTypes from 'prop-types';

const AnalyticsContext = React.createContext(() => {});

const AnalyticsProvider = ({ config, children }) => {
  if (typeof window === 'undefined') return children;
  return <AnalyticsContext.Provider value={config}>{children}</AnalyticsContext.Provider>;
};

AnalyticsProvider.propTypes = {
  children: PropTypes.node,
  config: PropTypes.object
};

export { AnalyticsContext, AnalyticsProvider };
