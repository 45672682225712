import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';

const ErrorPage = loadable(() => import('@quikr/ui/src/common/Error'));
const VAP = loadable(() => import('../../containers/VAP'));
const COVID = loadable(() => import('../../containers/COVID'));
const REDIRECT = loadable(() => import('../../containers/Redirect'));

const categories =
  // eslint-disable-next-line max-len
  'cars|cars-bikes|bikes-scooters|pets|matrimonial|community|entertainment|events|jobs|homes|home-lifestyle|electronics-appliances|mobiles-tablets';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" render={() => <h1>Homepage</h1>} />
      <Route exact path={'/bazaar/COVID'} component={COVID} />
      <Route exact path="/vap/:type(cars|core)/:adId" component={VAP} />
      {/* eslint-disable-next-line max-len, no-useless-escape */}
      <Route
        exact
        path={`/:category(${categories})/:slug([0-9a-zA-Z+-_.,]+)W0QQAdIdZ:adId([0-9]+)`}
        component={REDIRECT}
      />
      <Route
        exact
        path={'/:category(homes)/:slug([0-9a-zA-Z+-_.,]+)/p/:adId([0-9]+)/:projectId([0-9]+)'}
        component={VAP}
      />
      <Route
        exact
        path={'/:category(mobiles-tablets)/:slug([0-9a-zA-Z+-_.,]+)/p/:adId([0-9]+)/:entityId([0-9]+)'}
        component={VAP}
      />
      <Route
        exact
        path={`/:category(${categories})/:slug([0-9a-zA-Z+-_.,]+)/p/:adId([0-9]+)`}
        component={VAP}
      />
      <Route path="/404Error" component={ErrorPage} />
      <Route render={() => <Redirect to="/404Error" />} />
    </Switch>
  );
};

export default Routes;
