import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ImageView from '../ImageView';
import Typography from '../Typography';

const GridWrapper = styled.div`
  grid-gap: 3px;
  margin-bottom: 3px;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 5fr));
`;

const GridWrapperBottom = styled(GridWrapper)`
  margin-bottom: 0;
`;

const Grid6 = styled(ImageView)`
  width: auto;
  grid-column-end: span 6;
`;

const Grid4 = styled(ImageView)`
  width: auto;
  grid-column-end: span 4;
`;

const LastImage = styled.div`
  width: auto;
  grid-column-end: span 4;
`;

const MoreImages = styled(Typography)`
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
`;

const MoreImagesCount = styled(Typography)`
  font-size: 36px;
  line-height: 1.2;
`;

const ImageGrid = props => {
  const { images, alt } = props;
  return (
    <>
      <GridWrapper>
        <Grid6 url={images[0]} maxHeight={240} alt={`${alt}`} trigger={true} blur />
        <Grid6 url={images[1]} maxHeight={240} alt={`${alt}(1)`} blur />
      </GridWrapper>
      <GridWrapperBottom className="pR">
        <Grid4 url={images[2]} maxHeight={160} alt={`${alt}(2)`} blur />
        <Grid4 url={images[3]} maxHeight={160} alt={`${alt}(3)`} blur />
        <LastImage className="pR">
          <ImageView url={images[4]} maxHeight={160} alt={`${alt}(4)`} blur />
          {images && images.length > 5 && (
            <MoreImages tagName="div" modifiers="h3" className="pA l0 t0 w100 h100 dFA jcC fdC">
              <MoreImagesCount tagName="div" modifiers="medium">
                {`+${images.length - 5}`}
              </MoreImagesCount>
              {images.length - 5 > 1 ? 'IMAGES' : 'IMAGE'}
            </MoreImages>
          )}
        </LastImage>
      </GridWrapperBottom>
    </>
  );
};

ImageGrid.propTypes = {
  images: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  alt: PropTypes.string
};

export default ImageGrid;
