import React from 'react';
import styled from 'styled-components';
import Typography from '../Typography';
import PropTypes from 'prop-types';

const Policy = styled(Typography)`
  margin: 20px 0px 0;
  color: ${({ theme }) => theme.colors.TextGamma};
  a {
    color: ${({ theme }) => theme.colors.Primary};
  }
`;

const PolicyText = ({ className, urlTerms, urlPolicy }) => {
  return (
    <Policy className={`taC ${className}`} tagName="p" modifiers={['bt2']}>
      By proceeding, you agree to our{' '}
      <a className="wsN" href={urlTerms} target="_blank" rel="noreferrer">
        Terms of use
      </a>{' '}
      &{' '}
      <a className="wsN" href={urlPolicy} target="_blank" rel="noreferrer">
        Privacy Policy
      </a>
    </Policy>
  );
};

PolicyText.propTypes = {
  className: PropTypes.string,
  urlTerms: PropTypes.string,
  urlPolicy: PropTypes.string
};

PolicyText.defaultProps = {
  urlTerms: 'https://www.quikr.com/html/termsandconditions.php',
  urlPolicy: 'https://www.quikr.com/html/policies.php#privacy-policy'
};

export default PolicyText;
