import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Hr = styled.hr`
  height: 1px;
  background: ${({ theme }) => theme.colors.Neutral[300]};
  margin: 30px 0px;
  border: 0;
  ${({ hiddenXs }) =>
    hiddenXs &&
    css`
      @media (max-width: 767px) {
        display: none;
      }
    `};
  ${({ hiddenSm }) =>
    hiddenSm &&
    css`
      @media (min-width: 768px) and (max-width: 1024px) {
        display: none;
      }
    `};
`;

const HrTag = ({ hiddenXs, hiddenSm, ...rest }) => {
  return <Hr {...rest} hiddenXs={hiddenXs} hiddenSm={hiddenSm} />;
};
HrTag.propTypes = {
  hiddenXs: PropTypes.bool,
  hiddenSm: PropTypes.bool
};
export default HrTag;
