import React from 'react';
import PropTypes from 'prop-types';

const isStyleObject = obj => typeof obj === 'object';
class OtpInputs extends React.Component {
  constructor (props) {
    super(props);
    this.input = null;
  }

  componentDidMount () {
    const {
      input,
      props: { focus, shouldAutoFocus }
    } = this;

    if (input && focus && shouldAutoFocus) {
      input.focus();
    }
  }

  componentDidUpdate (prevProps) {
    const {
      input,
      props: { focus }
    } = this;

    // Check if focusedInput changed
    // Prevent calling function if input already in focus
    if (prevProps.focus !== focus && input && focus) {
      input.focus();
      input.select();
    }
  }

  getClasses = (...classes) => classes.filter(c => !isStyleObject(c) && c !== false).join(' ');

  render () {
    const {
      separator,
      isLastChild,
      focus,
      isDisabled,
      hasErrored,
      errorStyle,
      focusStyle,
      disabledStyle,
      shouldAutoFocus,
      value,
      ...rest
    } = this.props;

    return (
      <>
        <input
          className={this.getClasses(
            focus && focusStyle,
            isDisabled && disabledStyle,
            hasErrored && errorStyle
          )}
          type="tel"
          maxLength="1"
          ref={input => {
            this.input = input;
          }}
          disabled={isDisabled}
          value={value || ''}
          {...rest}
        />
        {!isLastChild && separator}
      </>
    );
  }
}
OtpInputs.propTypes = {
  separator: PropTypes.string,
  isLastChild: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasErrored: PropTypes.bool,
  errorStyle: PropTypes.string,
  focusStyle: PropTypes.object,
  disabledStyle: PropTypes.object,
  shouldAutoFocus: PropTypes.bool,
  value: PropTypes.string,
  focus: PropTypes.bool
};
export default OtpInputs;
