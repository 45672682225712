import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const FlexRow = styled.div`
  margin-left: -${(props) => props.margin}px;
  margin-right: -${(props) => props.margin}px;
  ${({ justify }) =>
    css`
      justify-content: ${justify};
    `};
  ${({ alignItems }) =>
    css`
      align-items: ${alignItems};
    `};
  ${({ direction }) =>
    css`
      flex-direction: ${direction};
    `};
  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const Row = ({ children, justify, direction, alignItems, margin, className, ...rest }) => {
  return (
    <FlexRow
      className={`dF fwW ${className || ''}`}
      {...rest}
      justify={justify}
      direction={direction}
      alignItems={alignItems}
      margin={margin}
    >
      {children}
    </FlexRow>
  );
};

Row.propTypes = {
  children: PropTypes.any,
  margin: PropTypes.number,
  justify: PropTypes.oneOf([
    'space-evenly',
    'space-around',
    'space-between',
    'flex-end',
    'center',
    'flex-start'
  ]),
  direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  alignItems: PropTypes.oneOf(['baseline', 'stretch', 'flex-end', 'center', 'flex-start']),
  className: PropTypes.string
};
Row.defaultProps = {
  margin: 12
};
export default Row;
