import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Icon = styled.svg``;

import config from '../../../config';
const { STATIC_FILE_PATH, STATIC_DOMAIN } = config;

const SvgComponent = ({ svgSprite, id, ...rest }) => {
  const svgPath = svgSprite
    .replace(STATIC_DOMAIN, '')
    .replace('http://localhost:3001', `/${STATIC_FILE_PATH}`);

  return (
    <Icon {...rest}>
      <use href={`${svgPath}#${id}`} />
    </Icon>
  );
};
SvgComponent.propTypes = {
  id: PropTypes.string.isRequired,
  svgSprite: PropTypes.node.isRequired
};

export default SvgComponent;
