import React from 'react';
import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    background: ${({ theme }) => theme.colors.Primary};
  }
  25% {
    background: #389acf;
  }
  50% {
    background: #76bae0;
  }
  100% {
    background: #b2d9ee;
  }
`;

const Dot = styled.span`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 4px;
  animation-name: ${loading};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;

const Dot1 = styled(Dot)`
  background: ${({ theme }) => theme.colors.Primary};
`;

const Dot2 = styled(Dot)`
  animation-delay: 0.25s;
  background: #389acf;
`;

const Dot3 = styled(Dot)`
  animation-delay: 0.5s;
  background: #76bae0;
`;

const Dot4 = styled(Dot)`
  animation-delay: 0.75s;
  background: #b2d9ee;
`;

const Loader = () => {
  return (
    <>
      <Dot1 />
      <Dot2 />
      <Dot3 />
      <Dot4 />
    </>
  );
};

export default Loader;
