import React from 'react';

import { useQuery } from '@apollo/client';
import loadable from '@loadable/component';
import { ThemeProvider } from 'styled-components';

import { EmptyTag } from '@quikr/ui';
import { GlobalStyle } from '@quikr/ui/theme';
import Quikr from '@quikr/ui/theme/Quikr';
import Routes from '../../components/Routes';
import Header from '../../components/common/Header';
import { AnalyticsConfig } from '../../constants/Analytics';
import { AppProvider } from '../../context';
import { AnalyticsProvider } from '../../context/ga';
import { NiroProvider } from '../../context/niro';
import { common } from '../../gql/common';

// const InterstitialAd = loadable(
//   () => import('../../components/common/InterstitialAd/InterstitialAd'),
//   { ssr: false }
// );

const Footer = loadable(p => import(`@quikr/ui/theme/${p.name}/components/Footer`));

const App = () => {
  const { data: commonData = {} } = useQuery(common);
  const { isWebview, isMobile, source, user } = commonData;

  return (
    <AppProvider user={user}>
      <AnalyticsProvider config={AnalyticsConfig(isMobile, source)}>
        <EmptyTag />
        <ThemeProvider theme={Quikr}>
          <GlobalStyle />
          {/*! isWebview && isMobile && <InterstitialAd />} */}
          {!isWebview && <Header host={'Quikr'} />}
          <NiroProvider>
            <Routes />
          </NiroProvider>
          {!isWebview && <Footer name={'Quikr'} />}
        </ThemeProvider>
      </AnalyticsProvider>
    </AppProvider>
  );
};
export default App;
