import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '../Typography';
import Button from '../Button';
import LazyImg from '../LazyImg';

import config from '../../../config';
const { IMAGE_CDN_DOMAIN } = config;

const Container = styled.div`
  height: ${props => props.maxHeight + 'px'};
  background: ${({ theme }) => theme.colors.Neutral[300]};
  @media (max-width: 768px) {
    height: 140px;
  }
`;

const Img = styled(LazyImg)`
  width: 140px;
  margin-bottom: 0;
  @media (max-width: 768px) {
    width: 70px;
  }
`;

const Text = styled(Typography)`
  color: ${({ theme }) => theme.colors.TextAlpha};
  margin: 24px 0 20px;
  @media (max-width: 768px) {
    margin: 8px 0 6px;
  }
`;

const NoImage = props => {
  const { imgUrl, requestPhotos, onRequest, className, ...rest } = props;
  return (
    <Container className={`pR dFA fdC jcC ${className || ''}`} {...rest}>
      <Img src={imgUrl} alt="" />
      <Text tagName="p" modifiers="h3">
        No images available
      </Text>
      {requestPhotos && onRequest && (
        <Button size="sm" btnType="secondary" onClick={onRequest}>
          Request Photos
        </Button>
      )}
    </Container>
  );
};

NoImage.propTypes = {
  imgUrl: PropTypes.string,
  requestPhotos: PropTypes.bool,
  maxHeight: PropTypes.number,
  onRequest: PropTypes.func,
  className: PropTypes.string
};

NoImage.defaultProps = {
  imgUrl: IMAGE_CDN_DOMAIN + '/public/core/images/default_1.png',
  requestPhotos: true,
  maxHeight: 400
};

export default NoImage;
