import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import TickIcon from '../../assets/call-flow-sprite.svg';
import SvgComponent from '../SvgComponent';

const Ul = styled.ul`
  padding: 24px 16px;
  margin: 10px 0px;
  background: #ffffff;
  border-radius: 4px;
  ${({ classType }) =>
    classType === 'shadow' &&
    css`
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
    `}
`;
const Li = styled.li`
  margin: 0px 0px 20px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.TextAlpha};
  &:last-child {
    margin-bottom: 0px;
  }
`;

const Span = styled.span`
  ${({ classType }) =>
    classType === 'tickIcon' &&
    css`
      background: #95d4a9;
      height: 20px;
      flex: 0 0 23px;
      margin-right: 20px;
      border-radius: 0 100px 100px 0;
      svg {
        width: 14px;
        height: 14px;
        fill: #fff;
        margin-left: 4px;
        margin-top: 3px;
      }
    `}
`;
const BuyerGuidelines = ({ data, showTick, boxShadow }) => {
  if (data) {
    return (
      <Ul classType={boxShadow ? 'shadow' : ''} className="dF fdC">
        {data.map((info, i) => {
          return (
            <Li key={i} className="dFA">
              {showTick && (
                <Span classType="tickIcon">
                  <SvgComponent svgSprite={TickIcon} id="ic_tick" />
                </Span>
              )}
              <Span>{info}</Span>
            </Li>
          );
        })}
      </Ul>
    );
  }
  return null;
};

BuyerGuidelines.propTypes = {
  data: PropTypes.array,
  showTick: PropTypes.bool,
  boxShadow: PropTypes.bool
};

BuyerGuidelines.defaultProps = {
  showTick: true,
  boxShadow: true
};

export default BuyerGuidelines;
