import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SvgComponent from '../SvgComponent';
import headerSprite from '../../assets/header-sprite.svg';
import { HeaderSelect } from '@quikr/ui';

const SearchCate = styled.div`
  @media screen and (max-width: 767px) {
    background: white;
    margin-left: -100%;
    transition: all 0.3s;
    &.active {
      margin-left: 0%;
    }
  }
  .mDiv {
    @media screen and (max-width: 767px) {
      background: ${({ theme }) => theme.colors.Primary};
      padding: 12px;
    }
    .searchDiv {
      background: white;
      @media screen and (min-width: 767px) {
        margin: 0 20px;
        height: 40px;
        width: 464px;
        border: 1px solid ${({ theme }) => theme.colors.Neutral[200]};
      }
      @media screen and (max-width: 767px) {
        border-radius: 4px;
      }
    }
  }
  input {
    width: 100%;
    padding: 11px;
    font-size: 14px;
    @media screen and (max-width: 767px) {
      padding-left: 0px;
    }
  }
`;

const Button = styled.button`
  border: none;
  background: transparent;
  border-radius: 2px;
  width: 40px;
  height: 38px;
  @media screen and (min-width: 767px) {
    border-left: 1px solid ${({ theme }) => theme.colors.Neutral[200]};
  }
  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.colors.Primary};
  }
`;
const MobileSearchBox = styled.div`
  background: white;
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 12px;
`;
const BackDiv = styled.div`
  padding: 7px 0px 17px;
  color: white;
  width: 30%;
  svg {
    margin-right: 10px;
    fill: white;
  }
`;

const SearchCategory = ({
  handleSearch,
  handleAutoComplete,
  autoSuggestions,
  onChange,
  searchedText,
  isMobile
}) => {
  const [searchVal, setSearchVal] = useState('');
  const [mobileSearch, setMobileSearch] = useState(false);
  const headerSelectInput = useRef();

  const clickMobileSearch = () => {
    setMobileSearch(!mobileSearch);
    if (!mobileSearch) headerSelectInput.current.focus();
  };

  const handleClick = () => {
    if (searchVal) handleSearch(searchVal);
  };

  const renderSelect = () => {
    return (
      <HeaderSelect
        items={autoSuggestions}
        valueKey="id"
        labelKey="keyword"
        headingKey="heading"
        onAutoComplete={handleAutoComplete}
        setSearchVal={setSearchVal}
        handleSearch={handleSearch}
        onChange={onChange}
        searchedText={searchedText}
        ref={headerSelectInput}
      />
    );
  };

  return (
    <>
      {isMobile ? (
        <MobileSearchBox className="w100 dFA" onClick={clickMobileSearch}>
          <Button className="dFA jcC">
            <SvgComponent svgSprite={headerSprite} id="ic_searc_1"></SvgComponent>
          </Button>
          Search
        </MobileSearchBox>
      ) : (
        ''
      )}
      <SearchCate
        className={`${isMobile ? 'w100 pF l0 t0 b0' : 'pR'} ${mobileSearch ? 'active' : ''}`}
      >
        <div className="mDiv pR">
          {isMobile ? (
            <BackDiv onClick={clickMobileSearch}>
              <div className="dFA">
                <SvgComponent
                  width={13}
                  height={13}
                  svgSprite={headerSprite}
                  id="ic_back"
                ></SvgComponent>{' '}
                Back
              </div>
            </BackDiv>
          ) : (
            ''
          )}
          <div className={`dFA searchDiv ${isMobile ? 'fdrR' : 'pR'}`}>
            {renderSelect()}
            <Button className="dFA jcC" onClick={handleClick}>
              <SvgComponent svgSprite={headerSprite} id="ic_searc_1"></SvgComponent>
            </Button>
          </div>
        </div>
      </SearchCate>
    </>
  );
};

SearchCategory.propTypes = {
  children: PropTypes.any,
  handleSearch: PropTypes.func,
  handleAutoComplete: PropTypes.func,
  autoSuggestions: PropTypes.array,
  onChange: PropTypes.func,
  searchedText: PropTypes.string,
  isMobile: PropTypes.bool
};

export default SearchCategory;
