import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 12px 0 0;
  max-width: 600px;
  margin: 0 auto;
`;

const Bar = styled.div`
  height: 2px;
  background: #fff;
`;

const Fill = styled.div`
  background: ${({ theme }) => theme.colors.Primary};
  width: ${props => props.percentage + '%'};
  transition: width 0.3s;
  will-change: width;
`;

const Text = styled.div`
  font-size: 16px;
  margin-left: 90px;
  color: #fff;
  font-weight: 500;
`;

const ProgressBar = ({ text, total, current, ...rest }) => {
  return (
    <Wrapper className="dFA">
      <Bar className="f1">
        <Fill className="h100" percentage={(current / total) * 100} />
      </Bar>
      <Text>{`${current}/${total} ${text}`}</Text>
    </Wrapper>
  );
};

ProgressBar.propTypes = {
  text: PropTypes.string,
  total: PropTypes.number,
  current: PropTypes.number
};

export default ProgressBar;
