import { useEffect } from 'react';

const useImageError = (adData) => {
  useEffect(() => {
    const logImageError = () => {
      const data = {
        adId: adData.ad.id,
        adType: adData.ad.adstyle
      };

      if (typeof fetch === 'function') {
        const payload = {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        };
        fetch(`${__WP_CLIENT_GQL_ENDPOINT}/o/log-image/submit`, payload);
      }
    };
    document.addEventListener('image-loading-error', logImageError);

    return () => {
      document.removeEventListener('image-loading-error', logImageError);
    };
  }, []);
};

export default useImageError;
