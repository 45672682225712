import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { interceptEvent } from '../../helpers/common';

import SvgComponent from '../SvgComponent';
import headerSprite from '../../assets/header-sprite.svg';

// Key handlers
const keyHandlers = {
  38: 'handleUpKey',
  40: 'handleDownKey',
  32: 'handleSpaceKey',
  13: 'handleEnterKey',
  27: 'handleEscKey',
  74: 'handleDownKey',
  75: 'handleUpKey'
};

const Div = styled.div`
  width: 100%;
  line-height: 46px;
  border1: 1px solid ${({ theme }) => theme.colors.TextGamma};
  @media screen and (min-width: 767px) {
    &:hover {
      border-color: ${({ theme }) => theme.colors.TextBeta};
    }
  }
  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.colors.Primary};
  }
  ${({ open }) =>
    open &&
    css`
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    `}
`;

const LabelDiv = styled.div`
  line-height: 17px;
`;

const HeadingDiv = styled.div`
  margin-top: 4px;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.TextGamma};
`;

const Input = styled.input`
  box-shadow: none;
  background: transparent;
  padding: 15px 14px;
  color: ${({ theme }) => theme.colors.TextAlpha};
`;

const Options = styled.ul`
  background: #fff;
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 10;
  overflow-y: auto;
  @media (min-width: 767px) {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1), 0 8px 8px 0 rgba(0, 0, 0, 0.05);
    max-height: 220px;
    min-height: 50px;
    border-radius: 4px;
  }
  border-radius: 0px;
  ${({ open }) =>
    open &&
    css`
      display: block;
    `}
  @media (max-width: 767px) {
    width: 100%;
    left: 0px;
    min-height: 84vh;
    max-height: 100%;
  }
`;
const OptionsSearchResultLi = styled.li`
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.Neutral[400]};
  line-height: 14px;
`;

const OptionsLi = styled.li`
  padding: 0px 15px;
  height: 48px;
  &:hover {
    transition: all 0.3s ease-in 0s;
    background: ${({ theme }) => theme.colors.Neutral[400]};
  }

  ${({ focused }) =>
    focused &&
    css`
      background: ${({ theme }) => theme.colors.Neutral[400]};
      color: ${({ theme }) => theme.colors.TextAlpha};
      transition: all 0.3s ease-in 0s;
    `}
  span {
    margin-top: 2px;
  }
  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.colors.Primary};
  }
`;

const TextDiv = styled.div`
  margin-top: 7px;
  margin-left: 16px;
  margin-bottom: 8px;
`;

const SpanVal = styled.span`
  pointer-events: none;
  left: 14px;
`;

const HeaderSelect = forwardRef((props, _selectInput) => {
  const {
    selectedVal,
    updateVal,
    noNullValues,
    onChange,
    closeOnChange,
    multiple,
    name = '',
    valueKey,
    items,
    onAutoComplete,
    labelKey,
    headingKey,
    searchable,
    readOnly,
    isMobile,
    disabled,
    // error,
    throttle,
    minLength,
    // isRequired,
    validation,
    setSearchVal,
    handleSearch,
    searchedText
  } = props;

  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(selectedVal || []);
  const [listItems, setListItems] = useState(items || []);
  const [searchText, setSearchTextState] = useState(searchedText || '');
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [timeoutHandle, setTimeoutHandle] = useState(null);
  // const { errors, register, setValue, clearErrors } = useFormContext() || {
  const { register, setValue, clearErrors } = useFormContext() || {
    errors: null,
    register: null,
    setValue: null,
    clearErrors: null
  };

  const getItems = () => {
    return listItems;
  };

  useEffect(() => {
    setListItems(items);
  }, [items]);

  const node = useRef(null);

  const filterItems = () => {
    const filterValue = (searchText || '').replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    const disableFilter = onAutoComplete !== null;

    if (filterValue && !disableFilter) {
      return (getItems() || []).filter(
        option =>
          option[labelKey]
            .replace(/[^a-zA-Z0-9]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) >= 0
      );
    }

    return getItems();
  };
  const renderValues = () => {
    const selcted = values || [];
    let lables = [];
    if (multiple && selcted.length) {
      lables = (getItems() || []).filter(
        option => selcted.indexOf(option[valueKey].toString()) >= 0
      );
    } else {
      lables = (getItems() || []).filter(
        option => option[valueKey] && option[valueKey].toString() === selcted
      );
    }
    const remainingLen = selcted.length - 2;
    return lables.length
      ? `${lables
          .slice(0, 2)
          .map(data => data[labelKey])
          .join(', ')} ${multiple && remainingLen > 0 ? `+ ${remainingLen} More` : ''}`
      : null;
  };
  const visibleOptions = filterItems();
  const isRenderVal = !!renderValues();

  useEffect(() => {
    if (register && name) {
      register({ name }, validation);
    }
  }, [register, name]);

  useEffect(() => {
    if (updateVal === undefined) {
      return;
    }
    setValues(updateVal);
    // Set selected value for hook form on re-render
    setValue && setValue(name, updateVal, { shouldDirty: !(updateVal === '') });
  }, [updateVal]);

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClick, false);
    }
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, [open]);

  useEffect(() => {
    if (searchText.length < minLength) {
      setListItems([]);
    }
    onAutoComplete && handleAutoComplete(searchText);
  }, [searchText]);

  const value = () => {
    if (Array.isArray(values)) {
      return values;
    } else if (values != null) {
      return [values];
    }
    return [];
  };
  const updateValues = values => {
    if (values || !noNullValues) {
      if (!multiple) {
        if (closeOnChange) {
          setOpen(false);
        }
      }
      setFocusedIndex(null);
      setSearchText('');
      setValues(values);
      // Set value for hook form context
      setValue && setValue(name, values, { shouldDirty: true });
      // Clear validation error messages
      clearErrors && clearErrors(name);
      onChange && onChange(values, name);
    }
  };
  const handleAutoComplete = query => {
    if (query.length < minLength) {
      return;
    }
    if (timeoutHandle) {
      clearTimeout(timeoutHandle);
    }
    setTimeoutHandle(
      setTimeout(() => {
        onAutoComplete(query);
      }, throttle)
    );
  };
  const handleClose = event => {
    interceptEvent(event);
    if (open) {
      setOpen(false);
      setFocusedIndex(null);
      // setSearchText('');
    }
  };
  const handleClick = e => {
    if (node.current && node.current.contains(e.target)) return;
    handleClose();
  };
  const setSearchText = text => {
    setSearchTextState(text);
    setSearchVal(text);
  };

  const handleSelect = (event, val) => {
    setSearchText('');
    interceptEvent(event);
    let selectedValue = val && val[valueKey] && val[valueKey].toString();
    if (!selectedValue) {
      selectedValue = event.target.getAttribute('data-value');
      if (selectedValue) selectedValue = selectedValue.toString();
    }
    if (selectedValue === null) return;
    if (selectedValue && value()[0] === selectedValue) {
      updateValues(null);
    } else {
      updateValues(selectedValue);
    }
  };
  const handleOpen = () => {
    !open && setOpen(true);
  };
  const handleFilter = event => {
    interceptEvent(event);
    const query = event.target.value;
    !open && setOpen(true);
    setSearchText(query);
  };
  const handleInputFocus = event => {
    interceptEvent(event);
    handleOpen();
  };
  const handleMouseDown = event => {
    searchable && handleOpen(event);
  };
  const keyHandlerActions = {
    handleUpKey: () => {
      moveIndex(-1);
    },
    handleDownKey: () => {
      moveIndex(1);
    },
    handleSpaceKey: event => {
      handleOpen();
      if (multiple) {
        keyHandlerActions.handleEnterKey(event);
      }
    },
    handleEnterKey: event => {
      handleSearch && handleSearch(searchText);
      if (focusedIndex != null) {
        handleSelect(event, visibleOptions[focusedIndex]);
      }
    },
    handleEscKey: event => {
      handleClose(event);
    }
  };
  const handleKeyDown = event => {
    if (keyHandlers[event.which]) {
      keyHandlerActions[keyHandlers[event.which]](event);
    }
  };
  const moveIndex = move => {
    const len = visibleOptions.length;
    const _focusedIndex = focusedIndex === null ? -1 : focusedIndex;
    const idx = (_focusedIndex + move + len) % len;
    setFocusedIndex(idx);
    setOpen(true);
  };

  const renderInput = () => {
    return (() => {
      return (
        <Input
          className="w100 boN outN pR"
          readOnly={readOnly}
          ref={_selectInput}
          required
          type="text"
          onChange={handleFilter}
          name="query"
          multiple={multiple}
          value={searchText}
          placeholder={renderValues() ? '' : 'Search'}
          onFocus={handleInputFocus}
          onMouseDown={handleMouseDown}
          autoComplete="off"
          disabled={disabled}
          inputLabelUp={!!renderValues()}
        />
      );
    })();
  };

  const renderOptions = () => {
    return (visibleOptions || []).map((item, index) => {
      let isSelected = false;
      if (item && item[valueKey]) {
        isSelected = value().slice(0).indexOf(item[valueKey].toString()) > -1;
      }
      return (
        <OptionsLi
          className="dFA pR cP"
          key={index}
          focused={index === focusedIndex}
          data-value={item[valueKey]}
          onMouseDown={handleMouseDown}
          onClick={handleSelect}
        >
          <SvgComponent
            svgSprite={headerSprite}
            id="ic_searc_1"
            data-value={item[valueKey]}
          ></SvgComponent>
          <TextDiv checked={isSelected} data-value={item[valueKey]}>
            <LabelDiv className="bt2" data-value={item[valueKey]}>
              {item[labelKey]}
            </LabelDiv>
            <HeadingDiv className="bt3" data-value={item[valueKey]}>
              in {item[headingKey]}
            </HeadingDiv>
          </TextDiv>
        </OptionsLi>
      );
    });
  };

  const shouldRenderInputMobile = () => {
    return getItems().length > 6 || onAutoComplete !== null;
  };

  return (
    <>
      <Div
        className="dF fdC outN"
        onKeyDown={handleKeyDown}
        onMouseDown={handleMouseDown}
        tabIndex="-1"
        id="sD"
        isMobile={isMobile}
        checkHeight={isRenderVal}
        ref={node}
        focused={open}
        selected={value().length !== 0}
      >
        {renderInput()}
        {!open && isRenderVal && <SpanVal className="pA t0">{renderValues()}</SpanVal>}
        {(visibleOptions.length > 0 || isMobile) && (
          <Options
            isMultiple={multiple}
            open={open}
            checkHeight={!shouldRenderInputMobile()}
            len={getItems().length}
            checkLabels={isRenderVal}
          >
            <OptionsSearchResultLi className="bt3 fwM">Search Results</OptionsSearchResultLi>
            {renderOptions()}
          </Options>
        )}
      </Div>
    </>
  );
});

HeaderSelect.propTypes = {
  throttle: PropTypes.number,
  minLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  closeOnChange: PropTypes.bool,
  items: PropTypes.array.isRequired,
  labelKey: PropTypes.string,
  headingKey: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onAutoComplete: PropTypes.func,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  selectedVal: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  updateVal: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  value: PropTypes.any,
  valueKey: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  noNullValues: PropTypes.bool,
  readOnly: PropTypes.bool,
  isMobile: PropTypes.bool,
  isRequired: PropTypes.bool,
  validation: PropTypes.object,
  setSearchVal: PropTypes.func,
  handleSearch: PropTypes.func,
  searchedText: PropTypes.string
};

HeaderSelect.defaultProps = {
  minLength: 2,
  throttle: 350,
  items: [],
  labelKey: 'label',
  headingKey: 'heading',
  multiple: false,
  searchable: true,
  disabled: false,
  valueKey: 'value',
  containerClass: '',
  closeOnChange: true,
  error: undefined,
  readOnly: false,
  isMobile: false,
  onChange: null,
  onAutoComplete: null,
  validation: {},
  name: ''
};

export default HeaderSelect;
