import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Radiolabel = styled.label`
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
  &:focus {
    input {
      opacity: 0.12;
    }
  }
`;
const RadioInput = styled.input`
  appearance: none;
  z-index: -1;
  left: -10px;
  top: -8px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(153, 153, 153, 1);
  opacity: 0;
  transform: scale(1);
  transition: opacity 0.3s, transform 0.2s;
  will-change: opacity, transform;
  &:checked {
    background-color: rgba(0, 131, 202, 1);
    + span {
      &:before {
        border-color: rgba(0, 131, 202, 1);
      }
    }
    + span {
      &:after {
        transform: translate(5px, 5px) scale(1);
      }
    }
  }
  &:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
    + span {
      &:before {
        border-color: rgba(0, 131, 202, 1);
      }
    }
  }
  &:disabled {
    opacity: 0;
    span {
      color: rgba(0, 0, 0, 0.38);
      cursor: initial;
      &:before {
        border-color: currentColor;
      }
    }
  }
  &:checked:active {
    + span {
      &:before {
        border-color: transparent;
        background-color: rgba(0, 0, 0, 0.6);
      }
    }
  }
  &:checked:disabled {
    + span {
      &:before {
        border-color: transparent;
        background-color: currentColor;
      }
    }
  }
`;

const Span = styled.span`
  &:before {
    content: '';
    margin: 2px 10px 2px 0;
    border: solid 2px; /* Safari */
    border-color: rgba(var(0, 0, 0), 0.6);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    vertical-align: top;
    transition: border-color 0.2s;
  }
  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: rgba(0, 131, 202, 1);
    transform: translate(5px, 5px) scale(0);
    transition: transform 0.2s;
  }
`;

const Radio = ({ name, value, disabled }) => {
  return (
    <Radiolabel className="pR dIB">
      <RadioInput className="pA dB m0 outN peN" type="radio" name={name || 'radio'} disabled={disabled} />
      <Span className="dF cP">{value}</Span>
    </Radiolabel>
  );
};
Radio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default Radio;
