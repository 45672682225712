import { useEffect, useRef } from 'react';

const useEventListener = (event, handler, value) => {
  const ref = useRef(null);
  const eventHandler = (node, e) => {
    if (e.target && !node.contains(e.target)) handler();
  };
  useEffect(() => {
    if (ref.current) {
      const node = ref.current;
      document.addEventListener(event, eventHandler.bind(null, node));
      return () => document.removeEventListener(event, eventHandler);
    }
  }, [ref, value]);
  return [ref];
};

export default useEventListener;
