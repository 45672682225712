import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const NoSSR = ({ children }) => {
  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
    setRenderComponent(true);
  }, []);
  if (renderComponent && process.env.BUILD_TARGET === 'client') {
    return <>{children}</>;
  }
  return null;
};

NoSSR.propTypes = {
  children: PropTypes.any.isRequired
};

export default NoSSR;
