import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useFormContext } from 'react-hook-form';
import Typography from '../Typography';
import CheckboxStyle from '../CheckboxStyle/CheckboxStyle';

const Checklabel = styled.label`
  color: ${({ theme }) => theme.colors.TextAlpha};
  line-height: 1.5;
  &:focus {
    input {
      opacity: 0.12;
    }
  }
`;

const Message = styled(Typography)`
  margin: 0px;
  bottom: -20px;
  color: ${({ theme }) => theme.colors.Error};
  ${({ classType }) => {
    switch (classType) {
      case 'error':
        return css`
          color: ${({ theme }) => theme.colors.Error};
        `;
      case 'success':
        return css`
          color: ${({ theme }) => theme.colors.Primary};
        `;
      case 'successMessage':
        return css`
          color: ${({ theme }) => theme.colors.Secondary};
        `;
      default:
        return css`
          color: ${({ theme }) => theme.colors.Primary};
        `;
    }
  }}
`;

const Span = styled.span`
  &:before {
    content: '';
    margin: 3px 11px 3px 1px;
    border: 2px solid rgba(153, 153, 153, 1);
    border-radius: 2px;
    width: 18px;
    height: 18px;
    vertical-align: top;
    transition: border-color 0.2s, background-color 0.2s;
  }
  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 1px;
    width: 12px;
    height: 6px;
    border: solid 2px transparent;
    border-right: none;
    border-top: none;
    transform: translate(3px, 4px) rotate(-45deg);
  }
`;

const Checkbox = ({
  name,
  value,
  dataVal,
  skipRegister,
  className,
  validation,
  disabled,
  ...rest
}) => {
  const { register, errors } = useFormContext() || {
    register: null,
    errors: null
  };
  const inputRef = useRef(null);

  useEffect(() => {
    if (register && !skipRegister) {
      register(inputRef.current, validation);
    }
  }, [register, validation]);

  return (
    <>
      {errors && errors[name] && (
        <Message tagName="p" modifiers="bt3" className="pA" classType="error">
          {errors[name].message}
        </Message>
      )}
      <Checklabel {...rest} className={`pR dIB ${className || ''}`}>
        <CheckboxStyle
          {...rest}
          ref={inputRef}
          className="pA dB m0 br50 outN peN"
          type="checkbox"
          name={name || 'checkbox'}
          disabled={disabled}
        />
        <Span className="dF cP" data-value={dataVal}>
          {value}
        </Span>
      </Checklabel>
    </>
  );
};

Checkbox.propTypes = {
  value: PropTypes.string,
  dataVal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  validation: PropTypes.object,
  name: PropTypes.string,
  skipRegister: PropTypes.bool,
  disabled: PropTypes.bool
};

Checkbox.defaultProps = {
  validation: {},
  skipRegister: false
};

export default Checkbox;
