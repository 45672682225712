import development from './development';
import stage from './stage';
import uat from './uat';
import production from './production';

const env = process.env.DEPLOY_ENV || 'development';

const config = {
  production,
  development,
  stage,
  uat
};

export default config[env];
