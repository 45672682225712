import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Typography from '../Typography';

const Menu = styled.div`
  background: #ffffff;
  padding: 5px 0px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1), 0 8px 8px 0 rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  min-width: 125px;
  width: auto;
  max-height: 225px;
  display: none;
  @media screen and (max-width: 768px) {
    min-width: auto;
  }
  ${({ open }) =>
    open &&
    css`
      display: block;
    `}
`;

const MenuItem = styled(Typography)`
  padding: 5px 10px;
  color: ${({ theme }) => theme.colors.TextAlpha};
  &:hover {
    color: ${({ theme }) => theme.colors.Primary};
  }
`;

const Label = styled(Typography)`
  color: ${({ theme }) => theme.colors.TextAlpha};
  padding: 0px 6px 4px;
`;

const MenuDropDown = ({ className, label, data, index, modifiers }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.addEventListener('click', toggleOpen, false);
    }
    return () => {
      document.removeEventListener('click', toggleOpen, false);
    };
  }, [open]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleClick = (value, cb) => {
    toggleOpen();
    cb();
  };

  if (data) {
    return (
      <div className="pR dIB">
        <Label
          tagName="label"
          className={`cP dB ${className}`}
          modifiers={modifiers}
          onClick={toggleOpen}
        >
          {label}
        </Label>
        <Menu className="pA zi1 r0" open={open}>
          {data.map((menuList, i) => {
            if (menuList.href) {
              return (
                <MenuItem
                  tagName="a"
                  className="dB"
                  modifiers={modifiers}
                  href={menuList.href}
                  key={i}
                  onClick={() => handleClick(menuList.value, () => menuList.handleClick(index))}
                >
                  {menuList.value}
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  tagName="a"
                  modifiers={modifiers}
                  className="dB"
                  key={i}
                  onClick={() => handleClick(menuList.value, () => menuList.handleClick(index))}
                >
                  {menuList.value}
                </MenuItem>
              );
            }
          })}
        </Menu>
      </div>
    );
  } else {
    return null;
  }
};

MenuDropDown.propTypes = {
  className: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.string,
  data: PropTypes.array
};

export default MenuDropDown;
