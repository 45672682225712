import { gql } from '@apollo/client';

export const common = gql`
  query {
    abRandValue @client
    isWebview @client
    source @client
    isMobile @client
    isAndroid @client
    category @client
    downloadApp @client
    isBot @client
    user @client
    city @client
  }
`;

export const getseller = gql`
  query GetSeller($id: ID!) {
    getverified(input: $id) {
      mobile_verified
      email_verified
      image_url
    }
  }
`;

export const getLocalities = gql`
  query GetLocality($id: Int!) {
    getLocalitiesByCityId(input: $id) {
      id
      name
    }
  }
`;

export const getTopCities = gql`
  query {
    topcities {
      id
      name
    }
  }
`;

export const ccrProducts = gql`
  query ccrProducts($ccrInput: CCRInput) {
    ccrproduct(input: $ccrInput) {
      id
      name
      url
      catid
      label
      imageUrl
      subcat
    }
  }
`;

export const citySuggest = gql`
  query GetCities($payload: CitySuggestInput!) {
    citysuggest(input: $payload) {
      id
      name
    }
  }
`;
