export default {
  Desktop: {
    header1: '1.75rem', // 28px
    header2: '1.5rem', // 24px
    header3: '1.25rem', // 20px
    bodyText1: '1rem', // 16px
    bodyText2: '0.875rem', // 14px
    bodyText3: '0.75rem' // 12px
  },
  Mobile: {
    header1: '1.6rem', // 22.4px
    header2: '1.5rem', // 21px
    header3: '1.142rem', // 16px
    bodyText1: '1rem', // 14px
    bodyText2: '0.857rem', // 12px
    bodyText3: '0.785rem' // 11px
  }
};
