import { gql } from '@apollo/client';

/**
 * Function that return a query based on token key
 * @param {String} TOKEN_KEY
 */
export const getTokens = TOKEN_KEY => gql`
  query getTokens @client {
    tokens @client {
      ${TOKEN_KEY} {
        action
        type
      }
    }
  }
`;

const AdKeysFragment = gql`
  fragment BasicAdDataKeys on Ad {
    id
    adlink
    meta {
      title
      description
    }
    views
    title
    adstyle
    description
    alttitle
    city {
      id
      name
    }
    timeago
    price
    verified
    attr {
      key
      value
      suffix
    }
    locality {
      id
      name
    }
    images {
      sm
      sq
      lg
      nr
      gv
    }
    highlights {
      ishighlight
      value
      color
    }
    cat {
      id
      name
    }
    subcat {
      id
      name
    }
    user {
      id
      name
      email
      chatemail
      profilelink
    }
    actions {
      top
      bottom
      gallery
    }
    breadcrumbs {
      label
      href
    }
    private
  }
`;

export const vapData = gql`
  query getAd($id: ID!, $category: String, $source: AdReqSource) {
    abRandValue @client
    isMobile @client
    isAndroid @client
    ad(id: $id, category: $category, source: $source) {
      ...BasicAdDataKeys
    }
  }
  ${AdKeysFragment}
`;

export const jobsVap = gql`
  query getJobsAd($id: ID!, $category: String, $source: AdReqSource) {
    abRandValue @client
    isMobile @client
    isAndroid @client
    user @client
    ad(id: $id, category: $category, source: $source) {
      ...BasicAdDataKeys
      createdtime
      expiretime
      stateregion {
        id
        name
      }
      minsalary
      maxsalary
      postedby
      localities
      externalredirecturl
      websiteurl
      subroles
      trustedBadges {
        key
        badge
      }
    }
  }
  ${AdKeysFragment}
`;

export const mobilesVap = gql`
  query getAd($id: ID!, $category: String, $source: AdReqSource, $entityId: ID) {
    abRandValue @client
    isMobile @client
    isAndroid @client
    ad(id: $id, category: $category, source: $source, entityId: $entityId) {
      ...BasicAdDataKeys
      entityDetails {
        key
        value
      }
    }
  }
  ${AdKeysFragment}
`;

export const homesVap = gql`
  query getHomesAd($id: ID!, $category: String, $source: AdReqSource, $projectid: ID) {
    abRandValue @client
    user @client
    isMobile @client
    isAndroid @client
    ad(id: $id, category: $category, source: $source, projectid: $projectid) {
      ...BasicAdDataKeys
      galleryImages {
        tab
        images
      }
      features {
        key
        value
        subtext
      }
      builderdetails {
        properties {
          key
          value
        }
        logo
        title
        name
        description
        href
      }
      projectdetails {
        properties {
          key
          value
        }
        logo
        title
        name
        description
        href
      }
      furnishing
      amenities
      propertytype
      coordinates {
        latitude
        longitude
      }
    }
  }
  ${AdKeysFragment}
`;

export const similarData = gql`
  query getSimilarAds($id: ID!) {
    similarads(id: $id) {
      id
      title
      alttitle
      adstyle
      locality {
        id
        name
      }
      price
      timeago
      createdtime
      adlink
      images {
        sm
        sq
        gv
        nr
        lg
      }
      highlights {
        ishighlight
        value
        color
      }
      city {
        id
        name
      }
      cat {
        id
        name
      }
      localities
      maxsalary
    }
  }
`;

export const personalisedData = gql`
  query PersonalisedAds($personalisedAdInput: PersonalisedAdInput) {
    personalisedads(input: $personalisedAdInput) {
      id
      title
      city {
        id
        name
      }
      cat {
        id
        name
      }
      adstyle
      locality {
        id
        name
      }
      localities
      adlink
      images {
        sm
        sq
        gv
        nr
        lg
      }
      highlights {
        ishighlight
        value
        color
      }
      price
      maxsalary
    }
  }
`;

export const getBannerConfig = gql`
  query getBannerConfig($payload: BannerConfigInput) {
    getBannerConfig(input: $payload) {
      image_url
      background_color
      text
      sub_text
      button_text
      url
      border
      same_tab
    }
  }
`;

export const reportad = gql`
  mutation ReportAd($reportAdInput: ReportAdInput) {
    reportad(input: $reportAdInput) {
      message
      success
    }
  }
`;

export const getSimilarSearches = gql`
  query similarsearches($payload: SearchInput) {
    similarsearches(input: $payload) {
      title
      links {
        url
        title
      }
    }
  }
`;

export const subHeaderLinks = gql`
  query qhsublinks($payload: CityInput) {
    qhsublinks(input: $payload) {
      left {
        label
        dropdown
        href
        isnew
        links {
          label
          href
        }
      }
      right {
        label
        dropdown
        href
        isnew
        links {
          label
          href
        }
      }
    }
  }
`;

export const nearbyLocalitiesData = gql`
  query getNearbyLocalities($id: ID!, $count: Int) {
    nearbylocalities(id: $id, count: $count) {
      type
      id
      name
      distance
    }
  }
`;
