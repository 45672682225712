import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '../Typography';
import { Ellipsis } from '../../helpers/common';

const Label = styled(Typography)`
  color: ${({ theme }) => theme.colors.Primary};
  display: contents;
`;

const ViewMore = ({ maxLength, content, showViewBtn, ...rest }) => {
  const [viewMore, setViewMore] = useState(false);
  const showButton = content.length > maxLength;
  return (
    <>
      {!viewMore ? Ellipsis(content, 0, maxLength) : content}
      {showButton && showViewBtn && (
        <Label
          tagName="span"
          modifiers="bt2"
          className="cP ttC"
          type="ViewMore"
          {...rest}
          onClick={() => setViewMore(!viewMore)}
        >
          {viewMore ? 'View Less' : 'View More'}
        </Label>
      )}
    </>
  );
};

ViewMore.propTypes = {
  content: PropTypes.any,
  maxLength: PropTypes.number,
  showViewBtn: PropTypes.bool
};

ViewMore.defaultProps = {
  maxLength: 100,
  showViewBtn: true
};

export default ViewMore;
