import { gql } from '@apollo/client';

const typeDefs = gql`
  type Query {
    isMobile: Boolean!
    isAndroid: Boolean
    category: String
    isWebview: Boolean
    source: String
    downloadApp: Boolean
    isBot: Boolean
    user: Object
    abRandValue: String
  }
`;

export default typeDefs;
