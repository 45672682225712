import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Switchlabel = styled.label`
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
  &:hover {
    input {
      opacity: 0.04;
      &:focus {
        opacity: 0.16;
      }
    }
  }
  &:focus {
    input {
      opacity: 0.12;
    }
  }
`;
const SwitchInput = styled.input`
  appearance: none;
  z-index: -1;
  right: 6px;
  top: -8px;
  width: 40px;
  height: 40px;
  background-color: rgba(153, 153, 153, 1);
  opacity: 0;
  transform: scale(1);
  transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
  will-change: opacity, transform;
  &:focus {
    opacity: 0.12;
  }
  &:checked {
    right: -10px;
    background-color: rgba(0, 131, 202, 1);
    + span {
      &:before {
        background-color: ${({ theme }) => theme.colors.Primary};
      }
    }
    + span {
      &:after {
        background-color: rgba(0, 131, 202, 1);
        transform: translateX(16px);
      }
    }
  }
  &:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
    + span {
      &:before {
        border-color: rgba(0, 33, 150, 243);
      }
    }
  }
  &:disabled {
    opacity: 0;
    span {
      color: rgba(0, 0, 0, 0.38);
      cursor: initial;
      &:before {
        border-color: currentColor;
      }
    }
  }
  &:checked:active {
    + span {
      &:before {
        background-color: rgba(0, 0, 0, 0.38);
      }
    }
  }
  &:checked:disabled {
    + span {
      &:before {
        background-color: rgba(33, 150, 243, 0.6);
      }
    }
  }
  &:disabled {
    opacity: 0;
    + span {
      color: rgba(0, 0, 0);
      opacity: 0.38;
      cursor: default;
      &:before {
        background-color: rgba(0, 0, 0, 0.38);
      }
    }
  }
`;

const Span = styled.span`
  &:before {
    content: '';
    float: right;
    display: inline-block;
    margin: 5px 0 5px 10px;
    border-radius: 7px;
    width: 36px;
    height: 14px;
    opacity: 0.5;
    background-color: rgba(0, 0, 0, 0.38);
    vertical-align: top;
    transition: background-color 0.2s, opacity 0.2s;
  }
  &:after {
    content: '';
    position: absolute;
    top: 2px;
    right: 16px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: rgba(255, 255, 255, 255);
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: background-color 0.2s, transform 0.2s;
  }
`;

const Radio = ({ name, value, disabled }) => {
  return (
    <Switchlabel className="dIB pR">
      <SwitchInput
        name={name || 'switch'}
        className="pA dB m0 br50 outN peN"
        type="checkbox"
        disabled={disabled}
      />
      <Span className="dIB w100 cP">{value}</Span>
    </Switchlabel>
  );
};
Radio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.string
};

export default Radio;
