import React from 'react';
import styled from 'styled-components';
import SvgComponent from '../SvgComponent';
import Typography from '../Typography';
import headerSprite from '../../assets/header-sprite.svg';

const ViewMoreImg = styled.div`
  flex: 0 0 180px;
  max-height: 215px;
  @media screen and (min-width: 400px) {
    max-height: 243px;
  }
  background: #fff;
`;
const Link = styled.div`
  span {
    border: 1px solid ${({ theme }) => theme.colors.Primary};
    width: 38px;
    height: 38px;
    svg {
      width: 15px;
      height: 15px;
      fill: ${({ theme }) => theme.colors.Primary};
      transform: rotate(180deg);
    }
  }
`;

const Text = styled(Typography)`
  margin: 10px 0 0;
  color: ${({ theme }) => theme.colors.Primary};
`;

const MoreImage = () => {
  return (
    <ViewMoreImg className="dFA jcC">
      <Link className="dFA fdC">
        <span className="dFA jcC br50">
          <SvgComponent svgSprite={headerSprite} id="ic_back" />
        </span>
        <Text tagName="p" modifiers="bt3">
          View All
        </Text>
      </Link>
    </ViewMoreImg>
  );
};

export default MoreImage;
