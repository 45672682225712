import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '../TextField';
import CalendarIcon from '../../icons/CalendarIcon';

const Icon = styled.span`
  top: 15px;
  right: 15px;
  svg {
    fill: ${({ theme }) => theme.colors.TextBeta};
  }
`;

const Calendar = props => {
  const { name, labelName, className, value, min, max, disabled, ...rest } = props;
  let _value;

  const returnDateString = inputDate => {
    const date = inputDate ? new Date(inputDate) : new Date();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  if (!value) {
    _value = returnDateString();
  } else if (value instanceof Date) {
    _value = returnDateString(value);
  }

  return (
    <div className={`pR ${className || ''}`}>
      <TextField
        min={min}
        max={max}
        inputName={name}
        labelName={labelName}
        type="date"
        value={_value || value}
        disabled={disabled}
        {...rest}
      />
      <Icon className="pA peN">
        <CalendarIcon width={20} />
      </Icon>
    </div>
  );
};

Calendar.propTypes = {
  name: PropTypes.string.isRequired,
  labelName: PropTypes.string,
  value: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default Calendar;
