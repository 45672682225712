import React from 'react';
import styled from 'styled-components';
const SVG = styled.svg`
  width: 16px;
  fill: #999;
`;

const Search = ({ ...rest }) => {
  return (
    <SVG viewBox="0 0 20 20" {...rest}>
      <path
        d="M11.4362815,5.93768224 L10.7099651,5.93768224 L10.4574924,6.18832542 C11.3493797,
        7.22840318 11.8918301,8.57675376 11.8918301,10.054085 C11.8918301,13.3380596 9.22988966,
        16 5.94591504,16 C2.66194043,16 0,13.3380596 0,10.054085 C0,6.77011034 2.66194043,
        4.10816992 5.94591504,4.10816992 C7.42324624,4.10816992 8.77068207,
        4.64970556 9.81075982,5.54067806 L10.0632325,5.29003488 L10.0632325,4.565548 L14.6351838,
        0 L15.9990852,1.36390144 L11.4362815,5.93768224 L11.4362815,5.93768224 Z M5.94591504,
        5.93768224 C3.67183123,5.93768224 1.82951232,7.78000114 1.82951232,10.054085 C1.82951232,
        12.327254 3.67183123,14.1704877 5.94591504,14.1704877 C8.2190841,14.1704877 10.0623178,
        12.327254 10.0623178,10.054085 C10.0623178,7.78000114 8.2190841,5.93768224 5.94591504,
        5.93768224 L5.94591504,5.93768224 Z"
        transform="translate(7.999543, 8.000000) scale(1, -1) translate(-7.999543, -8.000000) "
      ></path>
    </SVG>
  );
};

export default Search;
