import React from 'react';
import PropTypes from 'prop-types';
import SelectDropDown from '../DropDown';
import SearchCategory from '../CategorySearch';

const HeaderLoadable = ({
  isMobile,
  headerSprite,
  cityEnable = true,
  showSearch,
  handleSearch,
  handleAutoComplete,
  autoSuggestions,
  onChange,
  searchedText,
  city: { name } = {}
}) => {
  const displayName = name === 'www' ? 'All India' : name;
  return (
    <>
      {cityEnable && name && !isMobile && (
        <SelectDropDown
          dropInfo={{
            tag: 'svg',
            svgId: 'ic_location',
            name: displayName,
            dropDown: false
          }}
          svgSprite={headerSprite}
        />
      )}
      {showSearch && (
        <SearchCategory
          isMobile={isMobile}
          handleSearch={handleSearch}
          handleAutoComplete={handleAutoComplete}
          autoSuggestions={autoSuggestions}
          onChange={onChange}
          searchedText={searchedText}
        />
      )}
    </>
  );
};

HeaderLoadable.propTypes = {
  children: PropTypes.any,
  city: PropTypes.object,
  cityEnable: PropTypes.bool,
  showSearch: PropTypes.bool,
  citySlectionEnabale: PropTypes.bool,
  isMobile: PropTypes.bool,
  headerSprite: PropTypes.any,
  handleSearch: PropTypes.func,
  handleAutoComplete: PropTypes.func,
  autoSuggestions: PropTypes.array,
  onChange: PropTypes.func,
  searchedText: PropTypes.string
};

export default HeaderLoadable;
