import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NoImage from '../../common/NoImage';
import ImageView from '../../common/ImageView';
import ImageGrid from '../../common/ImageGrid';
import ImgCarousel from '../../common/ImgCarousel';
import MobileCarousel from '../../common/MobileCarousel';
import ViewMoreImg from '../../common/ViewMoreImg';
import Tag from '../../common/Tag';
// import Share from '../Share';
import LazyImg from '../LazyImg';
// import Shortlist from '../Shortlist';

const ImgContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.Neutral[300]};
  border-radius: 4px;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    border: none;
    border-radius: 0;
    margin-bottom: 0px;
    margin-left: -12px;
    margin-right: -12px;
  }
`;

const MobileImg = styled(LazyImg)`
  height: 215px;
  margin-right: 4px;
  flex: none;
  @media screen and (min-width: 400px) {
    height: 243px;
  }
`;

const VapTag = styled(Tag)`
  top: 0;
  @media (max-width: 768px) {
    top: auto;
    bottom: 0;
  }
`;

const VapTagWrapper = styled.div`
  top: 10px;
  right: 10px;
`;

const ImgCount = styled.span`
  background: rgba(0, 0, 0, 0.5);
  padding: 7px 30px;
  border-top-left-radius: 32px;
  color: #fff;
  text-transform: uppercase;
  @media (max-width: 768px) {
    padding: 4px 23px;
    border-top-left-radius: 28px;
    text-transform: none;
  }
`;

// const ShortlistComp = styled(Shortlist)`
//   margin-bottom: 6px;
// `;

const VapImage = props => {
  const {
    images,
    maxHeight,
    isMobile,
    adType,
    noImageUrl,
    title,
    openGallery,
    className,
    ...rest
  } = props;
  return (
    <ImgContainer
      className={`pR oH cP ${className || ''}`}
      {...rest}
      onClick={() => openGallery(true)}
    >
      {images.length === 0 ? (
        <NoImage imgUrl={noImageUrl} maxHeight={maxHeight} />
      ) : images.length === 1 ? (
        <>
          <ImageView url={images[0]} alt={`${title}`} blur maxHeight={maxHeight} trigger={true} />
          <ImgCount className="pA b0 r0 fwM">{images.length} photo</ImgCount>
        </>
      ) : images.length > 1 && isMobile ? (
        <>
          <MobileCarousel>
            {images.map((img, index) => {
              return index <= 3 ? (
                <MobileImg
                  noLazy={index < 2}
                  key={index}
                  src={img}
                  alt={index === 0 ? `${title}` : `${title}(${index})`}
                  trigger={index === 0 ? true : false}
                />
              ) : null;
            })}
            {images.length > 4 && <ViewMoreImg />}
          </MobileCarousel>
          <ImgCount className="pA b0 r0 fwM">{images.length + ' Images'}</ImgCount>
        </>
      ) : images.length < 5 ? (
        <>
          <ImgCarousel images={images} alt={title} />
          <ImgCount className="pA b0 r0 fwM">{images.length + ' IMAGES'}</ImgCount>
        </>
      ) : (
        <ImageGrid images={images} alt={title} />
      )}
      {adType !== 'BASIC' && (
        <VapTag className="pA l0 bt2" type={adType} cornerRounded={!isMobile} />
      )}
      {!isMobile && (
        <VapTagWrapper className="pA">
          {/* <ShortlistComp /> */}
          {/* <Share title={title} /> */}
        </VapTagWrapper>
      )}
    </ImgContainer>
  );
};

VapImage.propTypes = {
  images: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  maxHeight: PropTypes.number,
  adType: PropTypes.oneOf([
    'BASIC',
    'GOLD',
    'PLATINUM',
    'PREMIUM',
    'SPOTLIGHT',
    'PREMIUM_URGENT',
    'PREFERRED_SELLER'
  ]),
  noImageUrl: PropTypes.string,
  title: PropTypes.string,
  openGallery: PropTypes.func,
  className: PropTypes.string
};

VapImage.defaultProps = {
  maxHeight: 400,
  adType: 'basic'
};

export default VapImage;
