import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { usePagination, DOTS } from '../../hooks/usePagination';

const Ul = styled.ul`
  padding: 40px 0px;
`;
const Li = styled.li`
  padding: 5px 20px;
  color: ${({ theme }) => theme.colors.TextGamma};
  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.Primary};
  }
  &.next,
  &.prev {
    background: ${({ theme }) => theme.colors.PrimaryGradient};
    width: 32px;
    height: 32px;
    color: white;
    padding: 0px;
    &.hover {
      color: white;
    }
  }
`;

const Pagination = ({
  setOffset,
  limit,
  currentPage,
  setCurrentPage,
  totalCount,
  siblingCount = 1
}) => {
  const pageSize = limit;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 1) {
    return null;
  }
  const lastPage = paginationRange[paginationRange.length - 1];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0
    });
  };

  const changePage = val => {
    if (val === 'prev') {
      if (currentPage > 1) {
        scrollToTop();
        setCurrentPage(currentPage => currentPage - 1);
        setOffset((currentPage - 1) * limit);
      }
    } else if (val === 'next') {
      if (currentPage < lastPage) {
        scrollToTop();
        setCurrentPage(currentPage => currentPage + 1);
        setOffset((currentPage - 1) * limit);
      }
    } else {
      scrollToTop();
      const pageNumber = Number(val);
      setCurrentPage(pageNumber);
      setOffset((pageNumber - 1) * limit);
    }
  };

  return (
    <Ul className="dFA jcC">
      <Li tagName="li" className="prev br50 dFA jcC cP" onClick={() => changePage('prev')}>
        &#x3c;
      </Li>

      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return <Li key={pageNumber}>&#x2022;&#x2022;&#x2022;</Li>;
        }
        return (
          <Li
            key={pageNumber}
            className={`cP fwB ${currentPage === pageNumber ? 'active' : ''}`}
            modifiers={['bold']}
            onClick={e => changePage(e.target.textContent)}
          >
            {pageNumber}
          </Li>
        );
      })}

      <Li className="next br50 dFA jcC cP" onClick={() => changePage('next')}>
        &#x3e;
      </Li>
    </Ul>
  );
};

Pagination.propTypes = {
  setOffset: PropTypes.func,
  limit: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  totalCount: PropTypes.number,
  siblingCount: PropTypes.number
};

export default Pagination;
