import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import SvgComponent from '../SvgComponent';
import useEventListener from '@quikr/ssr/src/client/hooks/useEventListener';
import Button from '../Button';
import LazyImg from '../LazyImg';

const DropDown = styled.div`
  margin-right: 10px;
  padding-right: 30px;
  height: 40px;
`;
const loading = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
const DropDownList = styled.div`
  animation-name: ${loading};
  animation-duration: 0.3s;
  top: 7px;
  right: 5px;
  width: auto;
  border-radius: 2px;
  background: white;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  &::-webkit-scrollbar {
    display: none;
  }
  border-radius: 3px;
  @media screen and (min-width: 768px) {
    border-radius: 3px;
    right: 10px;
    top: 45px;
  }
`;
const ListKey = styled.a`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  transition: all 0.3s ease-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.Neutral[100]};
  }
`;
const Span = styled.span`
  margin-right: 5px;
  ${({ className }) =>
    className === 'login' &&
    css`
      height: 30px;
      width: 30px;
      background: ${({ theme }) => theme.colors.Neutral[500]};
      border-radius: 50%;
    `}
  svg {
    width: 20px;
    height: 20px;
    fill: #fff;
  }
`;
const Selected = styled.text``;
const SelectedLocation = styled.label`
  color: ${({ theme }) => theme.colors.TextAlpha};
`;
const Image = styled(LazyImg)`
  height: 30px;
  width: 30px;
  margin-right: 5px;
  border-radius: 50%;
`;
const Arrow = styled.span`
  top: 10px;
  right: 8px;
  svg {
    width: 10px;
    height: 10px;
  }
`;
const Icon = styled(SvgComponent)`
  width: 20px;
  height: 20px;
  margin-right: 15px;
`;
const Logout = styled(Button)`
  height: 40px;
  margin: 10px auto;
  padding: 0 55px 0 55px;
`;
const Profile = styled.div`
  height: 30px;
  width: 30px;
  margin-right: 5px;
  color: white;
  background: ${({ theme }) => theme.colors.PrimaryGradient};
  box-shadow: 0 2px 4px 0 rgba(171, 171, 171, 0.5);
`;

function deleteAllCookies () {
  window.location = 'https://www.quikr.com/bye';
}

const SelectDropDown = props => {
  const [display, setDisplay] = useState(false);
  const {
    dropInfo,
    svgSprite,
    options,
    optionsData,
    className,
    isMobile,
    logoutAction,
    ...rest
  } = props;
  const { tag, svgId, imageSrc, name, dropDown } = dropInfo || {};
  const handleClick = () => {
    setDisplay(!display);
  };
  const [ref] = useEventListener('click', () => setDisplay(false), display);
  let profileFiller = '';
  profileFiller = name === 'Hi Quikr User' ? 'Q' : name.charAt(0);

  return (
    <div className="pR v" ref={ref}>
      {!isMobile && (
        <DropDown className={`dFA cP pR ${className || ''}`} {...rest} onClick={handleClick}>
          {tag && (
            <>
              {tag === 'svg' ? (
                <Span className="dF">
                  {svgId === 'ic_location' ? (
                    <SvgComponent className="cD" svgSprite={svgSprite} id={svgId}></SvgComponent>
                  ) : (
                    <Profile className="dFA jcC boN br50 cP">{profileFiller}</Profile>
                  )}
                </Span>
              ) : (
                <Image src={imageSrc} />
              )}
            </>
          )}
          {svgId === 'ic_location' ? (
            <SelectedLocation className="fwB cD">{name}</SelectedLocation>
          ) : (
            <Selected className="cP">{name}</Selected>
          )}
          {dropDown && (
            <Arrow className="pA">
              <SvgComponent svgSprite={svgSprite} id="ic_dropdown"></SvgComponent>
            </Arrow>
          )}
        </DropDown>
      )}
      {isMobile && (
        <DropDown className={`dFA pR ${className || ''}`} {...rest} onClick={handleClick}>
          <Span className="dF">
            <SvgComponent svgSprite={svgSprite} id={svgId}></SvgComponent>
          </Span>
        </DropDown>
      )}
      {options && display && (
        <DropDownList className="pA oxH oyA wsN oH">
          <>
            {optionsData.map(({ href, label, svg }, index) => (
              <ListKey className="dB bt2 dFA" href={href} key={index}>
                <Icon svgSprite={svgSprite} id={svg}></Icon>
                {label}
              </ListKey>
            ))}
            <Logout
              className="dFA fwM jcC bt2"
              btnType="primary"
              href=""
              onClick={logoutAction || deleteAllCookies}
              type="button"
            >
              LOGOUT
            </Logout>
          </>
        </DropDownList>
      )}
    </div>
  );
};

SelectDropDown.propTypes = {
  logoutAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  dropInfo: PropTypes.object,
  svgSprite: PropTypes.any,
  options: PropTypes.any,
  optionsData: PropTypes.array,
  className: PropTypes.string,
  isMobile: PropTypes.bool
};

export default SelectDropDown;
