import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SVG = styled.svg`
  width: 22px;
  fill: #ffffff;
`;

const ShareIcon = props => {
  const { onClick } = props;
  return (
    <SVG onClick={onClick} viewBox="0 0 72 72">
      <path
        d="M55,49.6c-2.4,0-4.6,0.9-6.3,2.4L26.2,38.1c0.2-0.7,0.2-1.4,0.2-2.1c0-0.8-0.1-1.5-0.3-2.2l
        22.4-14c1.7,1.6,4,2.6,6.5,2.6c5.2,0,9.4-4.2,9.4-9.4c0-5.2-4.2-9.4-9.4-9.4c-5.2,0-9.4,
        4.2-9.4,9.4c0,0.5,0.1,1,0.1,1.6L23,28.7c-1.6-1.3-3.7-2.2-6-2.2c-5.2,0-9.4,4.2-9.4,9.4c0,
        5.2,4.2,9.4,9.4,9.4c2.3,0,4.4-0.8,6-2.2l22.7,14c-0.1,0.6-0.2,1.2-0.2,1.8c0,5.2,4.2,9.4,9.4,
        9.4c5.2,0,9.4-4.2,9.4-9.4C64.5,53.9,60.2,49.6,55,49.6z"
      />
    </SVG>
  );
};

ShareIcon.propTypes = {
  onClick: PropTypes.func
};

export default ShareIcon;
