import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  width: 40px;
  height: 40px;
  @media (max-width: 767px) {
    height: 26px;
    width: 26px;
    background: none;
    padding: 3px;
  }
`;

const VapSecondaryAction = (props) => {
  const { onClick, children, className, ...rest } = props;
  return (
    <Wrapper className={`dFA jcC cP ${className || ''}`} onClick={onClick} {...rest}>
      {children}
    </Wrapper>
  );
};

VapSecondaryAction.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string
};

export default VapSecondaryAction;
