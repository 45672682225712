import { useState, useRef, useEffect } from 'react';
import { checkForIntersectionObserver } from '@quikr/ui/src/helpers/common';

const useViewport = (opt = {}) => {
  const options = {
    root: opt.root || null,
    rootMargin: opt.margin || '0px',
    threshold: opt.threshold || 0
  };
  const [inViewport, setinViewport] = useState(false);
  const Ref = useRef(null);

  useEffect(() => {
    if (!inViewport) {
      if (checkForIntersectionObserver()) {
        const observer = new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting) {
            setinViewport(true);
          }
        }, options);
        observer.observe(Ref.current);
        return () => observer.disconnect();
      }
    }
  }, [inViewport, options]);

  return [Ref, inViewport];
};

export default useViewport;
