import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { loadableReady } from '@loadable/component';
import apolloClient from './apollo/client';
import App from './containers/App';

// Load all components needed before rendering
loadableReady(() => {
  hydrate(
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>,
    document.getElementById('root')
  );
});

if (module.hot) {
  module.hot.accept();
}
