export const quikrLinks = [
  {
    key: 'About Us',
    href: 'https://www.quikr.com/html/about.php'
  },
  {
    key: 'Contact Us',
    href: 'https://www.quikr.com/html/contact.php'
  },
  {
    key: 'Careers',
    href: 'https://www.quikr.com/html/jobs.php'
  },
  {
    key: 'Quikr Videos',
    href: 'https://www.quikr.com/video?id=vhS7M5IdOpI'
  },
  {
    key: 'Advertise With Us',
    href: 'https://www.quikr.com/adsales'
  },
  {
    key: 'Blog',
    href: 'http://blog.quikr.com/'
  },
  {
    key: 'Help',
    href: 'https://www.quikr.com/help/'
  },
  {
    key: 'Premium Ads',
    href: 'https://www.quikr.com/html/premium_ads.php'
  }
];

export const quikrPolicies = [
  {
    key: 'Listing Policy',
    href: 'https://www.quikr.com/html/policies.php#listing-policy'
  },
  {
    key: 'Terms of Use',
    href: 'https://www.quikr.com/html/termsandconditions.php'
  },
  {
    key: 'Privacy Policy',
    href: 'https://www.quikr.com/html/policies.php#privacy-policy'
  },
  {
    key: 'Mobiles Policy',
    href: 'https://www.quikr.com/html/policies.php#quikrX-policy'
  },
  {
    key: 'Brand Guidelines',
    href: 'https://www.quikr.com/html/brand_guidelines.php'
  },
  {
    key: 'Sitemap',
    href: 'https://www.quikr.com/Delhi-sitemap.php'
  },
  {
    key: 'News',
    href: 'http://news.quikr.com/',
    rel: true
  }
];

export const quikrBrands = [
  {
    key: 'Commonfloor',
    href: 'https://www.commonfloor.com/'
  },
  {
    key: 'Hiree',
    href: 'http://www.quikr.com/jobs'
  },
  {
    key: 'India Property',
    href: 'https://www.indiaproperty.com/'
  },
  {
    key: 'Zefo',
    href: 'https://www.quikr.com/bazaar'
  }
];

export const footerCities = [
  'Ahmedabad',
  'Bangalore',
  'Chandigarh',
  'Chennai',
  'Coimbatore',
  'Delhi',
  'Gurgaon',
  'Hyderabad',
  'Jaipur',
  'Kochi',
  'Kolkata',
  'Lucknow',
  'Mumbai',
  'Pune',
  'Trivandrum',
  'All Cities'
];

export const socialLinks = [
  {
    href: 'https://www.facebook.com/QuikrFans',
    icon: 'ic_facebook',
    key: 'facebook'
  },
  {
    href: 'https://twitter.com/quikr',
    icon: 'ic_twitter',
    key: 'twitter'
  },
  {
    href: 'https://www.linkedin.com/company/quikr/',
    icon: 'ic_linkedin',
    key: 'linkedin'
  },
  {
    href: 'https://www.youtube.com/channel/UCpLtpHTmLXwgNMA_OXvOQew',
    icon: 'ic_youtube',
    key: 'youtube'
  }
];

export const appLinks = [
  {
    href: 'https://apps.apple.com/in/app/quikr-free-local-classifieds/id632051273',
    icon: 'ic_apple',
    key: 'apple'
  },
  {
    href: 'https://play.google.com/store/apps/details?id=com.quikr',
    icon: 'ic_playstore',
    key: 'playstore'
  }
];
