import React from 'react';

const Close = ({ ...rest }) => {
  return (
    <svg fill="#000000" viewBox="0 0 20 20" {...rest}>
      <path
        d="M11.2,10l4.8,4.8L14.8,16L10,11.2L5.2,16L4,14.8L8.8,10L4,
        5.2L5.2,4L10,8.8L14.8,4L16,5.2L11.2,10z"
      />
    </svg>
  );
};

export default Close;
