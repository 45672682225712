import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Typo = styled.h1`
  ${({ size }) => {
    switch (size) {
      case 'h1':
        return css`
          font-size: ${({ theme }) => theme.typeScale.Mobile.header1};
          @media screen and (min-width: 768px) {
            font-size: ${({ theme }) => theme.typeScale.Desktop.header1};
          }
        `;
      case 'h2':
        return css`
          font-size: ${({ theme }) => theme.typeScale.Mobile.header2};
          @media screen and (min-width: 768px) {
            font-size: ${({ theme }) => theme.typeScale.Desktop.header2};
          }
        `;
      case 'h3':
        return css`
          font-size: ${({ theme }) => theme.typeScale.Mobile.header3};
          @media screen and (min-width: 768px) {
            font-size: ${({ theme }) => theme.typeScale.Desktop.header3};
          }
        `;
      case 'bt1':
        return css`
          font-size: ${({ theme }) => theme.typeScale.Mobile.bodyText1};
          @media screen and (min-width: 768px) {
            font-size: ${({ theme }) => theme.typeScale.Desktop.bodyText1};
          }
        `;
      case 'bt2':
        return css`
          font-size: ${({ theme }) => theme.typeScale.Mobile.bodyText2};
          @media screen and (min-width: 768px) {
            font-size: ${({ theme }) => theme.typeScale.Desktop.bodyText2};
          }
        `;
      case 'bt3':
        return css`
          font-size: ${({ theme }) => theme.typeScale.Mobile.bodyText3};
          @media screen and (min-width: 768px) {
            font-size: ${({ theme }) => theme.typeScale.Desktop.bodyText3};
          }
        `;
    }
  }};
  ${({ weight }) => {
    switch (weight) {
      case 'regular':
        return css`
          font-weight: 400;
        `;
      case 'medium':
        return css`
          font-weight: 500;
        `;
      case 'bold':
        return css`
          font-weight: bold;
        `;
      case 'semibold':
        return css`
          font-weight: 600;
        `;
    }
  }};
`;

const TypographyCom = ({ tagName, children, modifiers, className = '', ...rest }) => {
  const alter = Array.isArray(modifiers) ? modifiers : [modifiers];
  const size = alter.find((value) => /(h1|h2|h3|bt1|bt2|bt3)/.test(value));
  const weight = alter.find((value) => /(regular|medium|bold|semibold)/.test(value));
  return (
    <Typo as={tagName} weight={weight} size={size} className={className} {...rest}>
      {children}
    </Typo>
  );
};

TypographyCom.propTypes = {
  tagName: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'span',
    'div',
    'a',
    'li',
    'label'
  ]).isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};
TypographyCom.defaultProps = {
  tagName: 'div'
};

export default TypographyCom;
