import React from 'react';
import styled from 'styled-components';
const SVG = styled.svg`
  width: 16px;
  fill: #ddd;
`;

const DownArrow = ({ ...rest }) => {
  return (
    <SVG viewBox="0 0 24 24" id="Capa_1" {...rest}>
      <path xmlns="http://www.w3.org/2000/svg" d="M7 10l5 5 5-5z" />
    </SVG>
  );
};

export default DownArrow;
