import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Button from '../Button';
import { actionsMapping } from '../../helpers/action';

const BtnWrapper = styled.div`
  ${({ type }) => {
    switch (type) {
      case 'top_sticky':
      case 'top':
        return css`
          @media (max-width: 767px) {
            display: flex;
            flex-direction: column-reverse;
          }
        `;
      case 'gallery':
        return css`
          display: flex;
          flex-direction: row-reverse;
          button {
            box-shadow: none;
            &:first-child {
              margin-left: 16px;
            }
            &:last-child {
              border-color: #fff;
              color: #fff;
            }
          }
        `;
      default:
        return css`
          @media (max-width: 767px) {
            display: flex;
            flex-direction: row-reverse;
            position: fixed;
            left: 0;
            right: 0;
            width: 92%;
            bottom: 15px;
            background: #fff;
            z-index: 1;
            margin: 0 auto;
            box-shadow: -2px -2px 10px 0 rgba(0, 0, 0, 0.07), 2px 2px 8px 0 rgba(0, 0, 0, 0.12);
            border-radius: 6px;
            overflow: hidden;
          }
        `;
    }
  }}
`;

const Btn = styled(Button)`
  ${({ group }) => {
    switch (group) {
      case 'top_sticky':
        return css`
          min-width: 100px;
          margin-left: 12px;
          font-size: 16px;
          height: 40px;
          &:first-child {
            margin-left: 0;
          }
        `;
      case 'top':
      case 'gallery':
        return css`
          min-width: 200px;
          margin-left: 25px;
          border-radius: 4px;
          &:first-child {
            margin-left: 0;
          }
          @media (max-width: 767px) {
            min-width: 145px;
            margin: 10px 0 0;
            height: 42px;
            padding: 0 5px;
            text-transform: uppercase;
          }
          @media (max-width: 369px) {
            min-width: 110px;
            height: 36px;
          }
        `;
      default:
        return css`
          width: ${props => (props.full ? '100' : '50') + '%'};
          padding: 0px;
          border-radius: 0;
          &:last-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            border: 0;
          }
          &:nth-child(2) {
            color: ${({ theme }) => theme.colors.TextAlpha};
          }
        `;
    }
  }}
`;

const FixedBtns = ({ actions, actionClick, type, size, fireGa, ...rest }) => {
  const handleClick = action => {
    actionClick(action);
    if (fireGa) {
      switch (action) {
        case 'APPLY':
          type === 'top_sticky' || type === 'bottom'
            ? fireGa('stickyCtaSection_apply_init')
            : type === 'top' && fireGa('topCtaSection_apply_init');
          break;
        case 'JOBS_CALL':
          type === 'top_sticky' || type === 'bottom'
            ? fireGa('stickyCtaSection_contactRecruiter_init')
            : type === 'top' && fireGa('topCtaSection_contactRecruiter_init');
          break;
        case 'COMPLETED_STEPONE':
          type === 'top_sticky' || type === 'bottom'
            ? fireGa('stickyCtaSection_applyStep2_init')
            : type === 'top' && fireGa('topCtaSection_applyStep2_init');
          break;
        default:
          break;
      }
    }
  };
  return (
    <BtnWrapper type={type} {...rest}>
      {actions.map((action, index) => {
        return (
          <Btn
            className="fwB"
            group={type}
            full={!(actions.length > 1)}
            key={action}
            onClick={() => handleClick(action)}
            btnType={index === 0 ? 'primary' : 'secondary'}
            size={size || 'lg'}
            type="button"
          >
            {actionsMapping[action]}
          </Btn>
        );
      })}
    </BtnWrapper>
  );
};

FixedBtns.propTypes = {
  actions: PropTypes.array,
  actionClick: PropTypes.func,
  type: PropTypes.oneOf(['top', 'bottom', 'gallery', 'top_sticky']),
  size: PropTypes.oneOf(['lg', 'sm']),
  fireGa: PropTypes.func
};

export default FixedBtns;
