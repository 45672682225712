import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import OtpInput from './OtpInput';

const OtpInputs = styled.div`
  margin-bottom: 16px;
  input[type='tel'],
  input[type='number'] {
    width: 30px;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.Neutral[200]};
    font-size: 22px;
    font-weight: 700px;
    color: ${({ theme }) => theme.colors.TextAlpha};
    margin: 0 3px;
    text-align: center;
    &:focus {
      outline: none;
    }
  }
`;

const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
const SPACEBAR = 32;

class Otpfield extends React.Component {
  state = {
    activeInput: 0
  };

  getOtpValue = () => (this.props.value ? this.props.value.toString().split('') : []);

  // Helper to return OTP from input
  handleOtpChange = otp => {
    const { onChange } = this.props;
    const otpValue = otp.join('');
    onChange(otpValue);
  };

  // Focus on input by index
  focusInput = input => {
    const { numInputs } = this.props;
    const activeInput = Math.max(Math.min(numInputs - 1, input), 0);

    this.setState({ activeInput });
  };

  // Focus on next input
  focusNextInput = () => {
    const { activeInput } = this.state;
    this.focusInput(activeInput + 1);
  };

  // Focus on previous input
  focusPrevInput = () => {
    const { activeInput } = this.state;
    this.focusInput(activeInput - 1);
  };

  // Change OTP value at focused input
  changeCodeAtFocus = value => {
    const { activeInput } = this.state;
    const otp = this.getOtpValue();
    otp[activeInput] = value[0];

    this.handleOtpChange(otp);
  };

  // Handle pasted OTP
  handleOnPaste = e => {
    e.preventDefault();
    const { numInputs } = this.props;
    const { activeInput } = this.state;
    const otp = this.getOtpValue();

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = e.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    // Paste data from focused input onwards
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift();
      }
    }

    this.handleOtpChange(otp);
  };

  handleOnChange = e => {
    this.changeCodeAtFocus(e.target.value);
    this.focusNextInput();
  };

  // Handle cases of backspace, delete, left arrow, right arrow, space
  handleOnKeyDown = e => {
    if (e.keyCode === BACKSPACE || e.key === 'Backspace') {
      e.preventDefault();
      this.changeCodeAtFocus('');
      this.focusPrevInput();
    } else if (e.keyCode === DELETE || e.key === 'Delete') {
      e.preventDefault();
      this.changeCodeAtFocus('');
    } else if (e.keyCode === LEFT_ARROW || e.key === 'ArrowLeft') {
      e.preventDefault();
      this.focusPrevInput();
    } else if (e.keyCode === RIGHT_ARROW || e.key === 'ArrowRight') {
      e.preventDefault();
      this.focusNextInput();
    } else if (e.keyCode === SPACEBAR || e.key === ' ' || e.key === 'Spacebar') {
      e.preventDefault();
    }
  };

  checkLength = e => {
    if (e.target.value.length > 1) {
      e.preventDefault();
      this.focusNextInput();
    }
  };

  renderInputs = () => {
    const { activeInput } = this.state;
    const {
      numInputs,
      focusStyle,
      separator,
      isDisabled,
      disabledStyle,
      hasErrored,
      errorStyle,
      shouldAutoFocus
    } = this.props;
    const otp = this.getOtpValue();
    const inputs = [];

    for (let i = 0; i < numInputs; i++) {
      inputs.push(
        <OtpInput
          key={i}
          focus={activeInput === i}
          value={otp && otp[i]}
          onChange={this.handleOnChange}
          onKeyDown={this.handleOnKeyDown}
          onInput={this.checkLength}
          onPaste={this.handleOnPaste}
          onFocus={e => {
            this.setState({ activeInput: i });
            e.target.select();
          }}
          onBlur={() => this.setState({ activeInput: -1 })}
          separator={separator}
          focusStyle={focusStyle}
          isLastChild={i === numInputs - 1}
          isDisabled={isDisabled}
          disabledStyle={disabledStyle}
          hasErrored={hasErrored}
          errorStyle={errorStyle}
          shouldAutoFocus={shouldAutoFocus}
        />
      );
    }

    return inputs;
  };

  render () {
    return <OtpInputs>{this.renderInputs()}</OtpInputs>;
  }
}

Otpfield.propTypes = {
  separator: PropTypes.string,
  isLastChild: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasErrored: PropTypes.bool,
  errorStyle: PropTypes.string,
  focusStyle: PropTypes.object,
  disabledStyle: PropTypes.object,
  shouldAutoFocus: PropTypes.bool,
  value: PropTypes.string,
  numInputs: PropTypes.number,
  onChange: PropTypes.func
};

Otpfield.defaultProps = {
  numInputs: 4,
  isDisabled: false,
  shouldAutoFocus: false,
  value: ''
};
export default Otpfield;
