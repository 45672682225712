import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { errorLink, typeDefs } from '../../common/apollo';

const httpLink = createHttpLink({
  // eslint-disable-next-line
  uri: __WP_CLIENT_GQL_ENDPOINT,
  credentials: 'same-origin',
  headers: {
    channel: /Mobi/.test(navigator.userAgent) ? 'msite' : 'desktop'
  }
});

/**
 * Creates an apollo client
 */
const client = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  ssrForceFetchDelay: 100,
  typeDefs,
  resolvers: {
    Query: {
      isMobile: () => /Mobi/.test(navigator.userAgent)
    }
  }
});

export default client;