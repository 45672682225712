import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Typography from '../Typography';

const Ul = styled.ul`
  display: flex;
  width: fit-content;
  margin-bottom: 16px;
  @media (max-width: 767px) {
    margin-top: 16px;
  }
`;
const Li = styled(Typography)`
  ${({ colorCode, theme }) =>
    css`
      color: ${colorCode ? theme.colors[colorCode] : theme.colors.TextDelta};
    `};
  @media (max-width: 767px) {
    &:before {
      height: 3px;
      width: 3px;
    }
  }
  &:before {
    content: '';
    height: 6px;
    width: 6px;
    margin: 0 10px;
    background: ${({ theme }) => theme.colors.TextDelta};
    border-radius: 50%;
  }
  &:first-child {
    margin-left: 0px;
    &:before {
      display: none;
    }
  }
`;

const Bullets = ({ pageType = '', data, ...rest }) => {
  if (data) {
    return (
      <Ul className="jcSB pR" {...rest}>
        {data.map((points, i) => (
          <Li
            className="dFA ttU"
            modifiers={[pageType === 'snb' ? 'bt3' : 'bt2', 'bold']}
            tagName="li"
            key={i}
            colorCode={points.color}
          >
            {points.value}
          </Li>
        ))}
      </Ul>
    );
  } else {
    return null;
  }
};

Bullets.propTypes = {
  data: PropTypes.array,
  pageType: PropTypes.string
};

export default Bullets;
