import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import Typography from '../Typography';
import Close from '../../icons/Close';

const slideAnimate = (position, frames = [], direction) => {
  const value = Position[position].bottom;
  const lvalue = Position[position].left;
  const path = lvalue === 50 ? 'bottom' : 'left';
  const pv = lvalue === 50 ? 2 : 1;
  const string = frames.reduce((acc, each, index) => {
    const opac = direction === 'in' ? each : 100 - each;
    acc =
      acc +
      `${each}%{${path}:${
        (path === 'left' ? lvalue : value) < 50 ? 100 - (index + 1) * pv : index + 1
      }%;opacity:${opac / 100}}`;
    return acc;
  }, '');
  return keyframes`${string}`;
};

const slideIn = position => slideAnimate(position, [0, 25, 50, 75, 100], 'in');

const slideOut = position => slideAnimate(position, [100, 75, 50, 25, 0], 'in');

const fadeIn = () => keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const fadeOut = () => keyframes`
    100% {
        opacity: 0;
    }
    0% {
        opacity: 1;
    }
`;

const growIn = position => {
  const value = Position[position].left;
  return keyframes`
    0% {
        transform: translateX(-${100 - value}%) scale(0,0);
        opacity: 0;
    }
    25% {
        transform: translateX(-${100 - value}%) scale(0.25,0.25);
        opacity: 0.50;
    }
    50% {
        transform: translateX(-${100 - value}%) scale(0.5,0.5);
        opacity:0.70;
    }
    75% {
        transform:translateX(-${100 - value}%)  scale(0.75,0.75);
        opacity: 0.85;
    }
    100% {
        transform: translateX(-${100 - value}%) scale(1,1);
        opacity: 1;
    }
`;
};

const growOut = position => {
  const value = Position[position].left;
  return keyframes`
    100% {
        transform: translateX(-${100 - value}%) scale(0,0);
        opacity: 0;
    }
    75% {
        transform: translateX(-${100 - value}%) scale(0.25,0.25);
        opacity: 0.50;
    }
    50% {
        transform: translateX(-${100 - value}%) scale(0.5,0.5);
        opacity:0.70;
    }
    25% {
        transform:translateX(-${100 - value}%)  scale(0.75,0.75);
        opacity: 0.85;
    }
    0% {
        transform: translateX(-${100 - value}%) scale(1,1);
        opacity: 1;
    }
`;
};

const Message = styled(Typography)`
  color: ${({ theme }) => theme.colors.Neutral[600]};
  margin: 0px 20px;
  max-width: 400px;
`;

const Wrapper = styled.div`
  ${({ position, type, theme }) => css`
    position: fixed;
    left: ${100 - Position[position].left}%;
    bottom: ${100 - Position[position].bottom}%;    
    right: auto;
    height: 50px;
    min-width: 350px;
    background-color: ${
      type === 'error'
        ? theme.colors.Failure
        : type === 'success'
        ? theme.colors.Secondary
        : theme.colors.TextAlpha
    };};
    border-radius: 6px;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,0.20);
    z-index: 10;
    ${({ state, animationClass }) =>
      css`
        animation: ${state
            ? Animation[animationClass].In(position)
            : Animation[animationClass].Out(position)}
          0.2s linear;
      `}
    transform: translateX(-${100 - Position[position].left}%)
  `}
`;

const CloseIcon = styled(Close)`
  width: 25px;
  fill: ${({ theme }) => theme.colors.TextAlpha};
`;

const CloseBar = styled.div`
  margin-right: 20px;
  color: ${({ theme }) => theme.colors.Primary};
  cursor: pointer;
`;

const Animation = {
  Grow: {
    In: growIn,
    Out: growOut
  },
  Slide: {
    In: slideIn,
    Out: slideOut
  },
  Fade: {
    In: fadeIn,
    Out: fadeOut
  }
};

const Position = {
  'top-right': {
    left: 5,
    bottom: 10
  },
  'top-middle': {
    left: 50,
    bottom: 10
  },
  'top-left': {
    left: 95,
    bottom: 10
  },
  'bottom-right': {
    left: 5,
    bottom: 95
  },
  'bottom-middle': {
    left: 50,
    bottom: 95
  },
  'bottom-left': {
    left: 95,
    bottom: 95
  }
};

const Snackbar = ({
  message,
  type,
  animationClass,
  timeout,
  autoHide,
  position,
  onClose,
  callbacks = []
}) => {
  const [state, setstate] = useState(true);
  const handleClose = () => {
    setstate(false);
    onClose();
  };

  const onAnimationEnd = () => {
    const cb = callbacks.find(each => each.cbType === 'close');
    !state && cb && cb(false);
  };

  useEffect(() => {
    if (autoHide) {
      const timer = setTimeout(() => {
        handleClose();
      }, timeout);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <Wrapper
      className="dFA jcSB"
      type={type}
      state={state}
      position={position}
      animationClass={animationClass}
      onAnimationEnd={onAnimationEnd}
    >
      <Message tagName="p" modifiers={['bt2', 'bold']}>
        {message}
      </Message>
      {callbacks.map(({ cb, text, cbType }, index) => (
        <CloseBar key={index} onClick={cbType === 'close' ? () => handleClose(cb) : cb}>
          {cbType === 'close' && type === 'success' ? (
            <CloseIcon />
          ) : (
            <Typography tagName="div" modifiers={['bt2']}>
              {text}
            </Typography>
          )}
        </CloseBar>
      ))}
    </Wrapper>
  );
};

Snackbar.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['error', 'success', 'standard']),
  timeout: PropTypes.number,
  animationClass: PropTypes.oneOf(['Grow', 'Slide', 'Fade']),
  position: PropTypes.oneOf([
    'top-right',
    'top-middle',
    'top-left',
    'bottom-right',
    'bottom-middle',
    'bottom-left'
  ]),
  callbacks: PropTypes.arrayOf(
    PropTypes.shape({
      cb: PropTypes.func,
      text: PropTypes.string,
      cbType: PropTypes.string
    })
  ),
  autoHide: PropTypes.bool,
  onClose: PropTypes.func
};
Snackbar.defaultProps = {
  type: 'standard',
  timeout: 10000,
  autoHide: true,
  animationClass: 'Grow',
  position: 'bottom-left'
};
export default Snackbar;
