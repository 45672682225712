import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import TickIcon from '../../assets/call-flow-sprite.svg';
import SvgComponent from '../SvgComponent';

const Ul = styled.ul`
  padding: 24px 10px 24px 0;
  margin: 10px 0px;
`;
const Li = styled.li`
  margin: 0px 0px 15px;
  color: ${({ theme }) => theme.colors.TextAlpha};
  &:last-child {
    margin-bottom: 0px;
  }
`;

const Span = styled.span`
  ${({ className }) =>
    className === 'tickIcon' &&
    css`
      background: #fff;
      border: 1px solid ${({ theme }) => theme.colors.Neutral[200]};
      height: 23px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex: 0 0 23px;
      margin-right: 20px;
      border-radius: 50%;
      svg {
        width: 16px;
        height: 16px;
        fill: ${({ theme }) => theme.colors.Secondary};
      }
      @media (min-width: 767px) {
        height: 36px;
        flex: 0 0 36px;
        svg {
          width: 22px;
          height: 22px;
        }
      }
    `}
`;
const SafetyGuideLines = ({ data, showTick }) => {
  if (data) {
    return (
      <Ul className="dF fdC">
        {data.map((info, i) => {
          return (
            <Li className="dFA" key={i}>
              {showTick && (
                <Span className="tickIcon">
                  <SvgComponent svgSprite={TickIcon} id="ic_tick" />
                </Span>
              )}
              <Span>{info}</Span>
            </Li>
          );
        })}
      </Ul>
    );
  } else {
    return null;
  }
};

SafetyGuideLines.propTypes = {
  data: PropTypes.array,
  showTick: PropTypes.bool
};

SafetyGuideLines.defaultProps = {
  showTick: true
};

export default SafetyGuideLines;
