import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ViewMore from '../ViewMore';

const Li = styled.li`
  text-align: left;
  margin-bottom: 24px;
`;
const Div = styled.div`
  ${({ type }) =>
    type === 'question'
      ? css`
          color: ${({ theme }) => theme.colors.TextAlpha};
          margin-bottom: 10px;
        `
      : css`
          color: ${({ theme }) => theme.colors.TextBeta};
          margin: 10px 0px;
        `}
`;
const Label = styled.span`
  margin-right: 10px;
  ${({ type }) => {
    switch (type) {
      case 'question':
        return css`
          color: ${({ theme }) => theme.colors.TextAlpha};
        `;
      case 'answer':
        return css`
          color: ${({ theme }) => theme.colors.TextBeta};
        `;
    }
  }}
`;
const QuestionAnswer = props => {
  const {
    data,
    maxLengthForQuestion,
    maxLengthForAnswer,
    viewFullQuestion,
    viewFullAnswer
  } = props;
  if (data) {
    return (
      <ul>
        {data.map((info, i) => {
          return (
            <Li key={i}>
              {info.question && (
                <Div className="dF fwM" type="question">
                  <Label type="question">Q.</Label>
                  {!viewFullQuestion ? (
                    <ViewMore content={info.question} maxLength={maxLengthForQuestion} />
                  ) : (
                    info.question
                  )}
                </Div>
              )}
              {info.answer && (
                <Div className="dF" type="answer">
                  <Label type="answer">A.</Label>
                  {!viewFullAnswer ? (
                    <ViewMore content={info.answer} maxLength={maxLengthForAnswer} />
                  ) : (
                    info.answer
                  )}
                </Div>
              )}
            </Li>
          );
        })}
      </ul>
    );
  } else {
    return null;
  }
};

QuestionAnswer.propTypes = {
  data: PropTypes.array.isRequired,
  maxLengthForQuestion: PropTypes.number,
  maxLengthForAnswer: PropTypes.number,
  viewFullQuestion: PropTypes.bool,
  viewFullAnswer: PropTypes.bool
};

export default QuestionAnswer;
