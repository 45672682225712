export const actionsMapping = {
  CALL: 'CALL',
  CONTACT_BROKER: 'CONTACT BROKER',
  CONTACT_BUILDER: 'CONTACT BUILDER',
  CONTACT_OWNER: 'CONTACT OWNER',
  CHAT: 'CHAT',
  WHATSAPP: 'WHATSAPP',
  APPLY: 'APPLY',
  JOBS_CALL: 'CALL',
  COMPLETED_STEPONE: 'APPLY (STEP 2)',
  JOBS_WHATS_APP: 'WHATSAPP'
};
