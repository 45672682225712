import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LazyImg from '../LazyImg';

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${props => props.maxHeight + 'px'};
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  @media (max-width: 768px) {
    height: 200px;
  }
`;

const Image = styled(LazyImg)`
  max-width: 100%;
  max-height: ${props => props.maxheight + 'px'};
  @media (max-width: 768px) {
    max-height: 200px;
  }
`;

const BlurView = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(1.2);
  filter: blur(20px);
  z-index: -1;
`;

const ImageView = props => {
  const { url, alt, imgClass, blur, onClick, maxHeight, className, trigger, ...rest } = props;
  return (
    <ImageWrapper
      className={`pR jcC oH ${className || ''}`}
      maxHeight={maxHeight}
      onClick={onClick}
      {...rest}
    >
      {blur && (
        <BlurView className="pA w100 h100" style={{ backgroundImage: 'url(' + url + ')' }} />
      )}
      <Image
        className={`pR ${imgClass || ''}`}
        maxheight={maxHeight}
        src={url}
        alt={alt}
        trigger={trigger}
      />
    </ImageWrapper>
  );
};

ImageView.propTypes = {
  alt: PropTypes.string,
  url: PropTypes.string.isRequired,
  imgClass: PropTypes.string,
  blur: PropTypes.bool,
  onClick: PropTypes.func,
  maxHeight: PropTypes.number,
  className: PropTypes.string,
  trigger: PropTypes.bool
};

ImageView.defaultProps = {
  alt: '',
  maxHeight: 400
};

export default ImageView;
