import { useContext } from 'react';
import { AnalyticsContext } from '../context/ga';

const useGa = () => {
  const { profile = 'MOBILE', source = 'web' } = useContext(AnalyticsContext);

  return {
    TriggerGaEvent: ({
      category,
      label = '',
      action,
      cityName = '',
      catName = '',
      subcatName = '',
      callback
    }) => {
      let eventCategory = category;
      let eventAction = action || cityName;
      // eslint-disable-next-line
      if (__ANALYTICS_DOMAIN_CLIENT__ === 'Quikr') {
        eventCategory = `${category}${catName ? `_${catName}` : ''}`.replace(/\s|&/g, '');
        eventAction = action || `${subcatName}_${cityName}`;
        eventAction = eventAction.replace(/\s|&/g, '');
      }
      gtag('event', label.replace(/\s|&/g, ''), {
        device_type: profile,
        event_source: source,
        event_category: eventCategory,
        event_label: eventAction,
        event_callback: callback
      });
    }
  };
};

export default useGa;
