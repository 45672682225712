import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Ellipsis } from '../../helpers/common';
import Typography from '../Typography';

const Desc = styled(Typography)`
  line-height: 1.6;
  &:first-of-type {
    padding-top: 10px;
  }
  color: ${({ theme }) => theme.colors.TextBeta};
  word-break: break-word;
`;

const Span = styled(Typography)`
  color: ${({ theme }) => theme.colors.Primary};
  display: contents;
`;

const trimmingLength = (contentArray, tillIndex, maxLength) => {
  return tillIndex > 0 ? maxLength - contentArray[tillIndex - 1].length : maxLength;
};

const findApproximateContent = (paragraph, maxLength) => {
  let array = [];
  let size = 0;
  const contentArray = paragraph.reduce((acc, each, index) => {
    acc.push({
      content: each,
      index,
      length: each.length + size
    });
    size = each.length + size;
    return acc;
  }, []);
  if (maxLength >= size) {
    return false;
  }
  const tillIndex = contentArray.find((each, i) => maxLength < each.length).index;
  const { content } = contentArray[tillIndex];
  contentArray[tillIndex].content = Ellipsis(
    content,
    trimmingLength(contentArray, tillIndex, maxLength)
  );
  array = contentArray.map(each => each.content).slice(0, tillIndex + 1);
  return array;
};

const Description = ({ content, maxLength, modifiers, className }) => {
  const [viewmore, setViewmore] = useState(true);
  const ContentToBeShown = findApproximateContent(content, maxLength);
  const showBtn = !!ContentToBeShown;
  const data = (viewmore && ContentToBeShown) || content;
  const title = viewmore ? ' View More' : ' View Less';
  return (
    <>
      {data.map((each, i) => {
        return (
          <React.Fragment key={i}>
            <Desc tagName="span" modifiers={modifiers || 'bt1'} className={className}>
              {each.trim()}
            </Desc>
            {i < data.length - 1 ? <br /> : null}
          </React.Fragment>
        );
      })}
      {showBtn && (
        <Span
          tagName="span"
          modifiers={modifiers || 'bt1'}
          className="cP"
          onClick={() => setViewmore(!viewmore)}
        >
          {title}
        </Span>
      )}
    </>
  );
};

Description.propTypes = {
  content: PropTypes.array,
  maxLength: PropTypes.any,
  modifiers: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  className: PropTypes.string
};

export default Description;
