import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const List = styled.li`
  color: #5b7194;
  &:after {
    content: '';
    color: ${({ theme }) => theme.colors.Primary};
    margin: 0 12px;
    width: 6px;
    height: 6px;
    background: #5b7194;
    border-radius: 6px;
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
`;

const SubCategory = () => {
  return (
    <ul className="dFA ttU bt2">
      <List className="dFA fwB">GENTLY USED</List>
      <List className="dFA fwB">Sofa Sets</List>
      <List className="dFA fwB">GENTLY USED</List>
    </ul>
  );
};

SubCategory.propTypes = {
  data: PropTypes.any
};

export default SubCategory;
