import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ArrowIcon from '../../icons/Arrow';

const CarouselBtn = styled.span`
  svg {
    fill: ${({ theme }) => theme.colors.TextBeta};
    transform: ${({ type }) => (type === 'right' ? 'rotate(180deg)' : 'none')};
  }
  ${({ small }) =>
    small
      ? css`
          width: 36px;
          height: 36px;
          top: calc(50% - 18px);
          svg {
            height: 12px;
          }
        `
      : css`
          width: 48px;
          height: 48px;
          top: calc(50% - 24px);
          svg {
            height: 18px;
          }
        `}
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  background: #fff;
`;

const CarouselButtons = ({ type, className, small, ...props }) => {
  return (
    <CarouselBtn
      type={type}
      small={small}
      className={`dFA jcC cP pA br50 ${className || ''}`}
      {...props}
    >
      <ArrowIcon />
    </CarouselBtn>
  );
};

CarouselButtons.propTypes = {
  type: PropTypes.oneOf(['left', 'right']),
  small: PropTypes.bool,
  className: PropTypes.string
};

CarouselButtons.defaultProps = {
  type: 'left',
  small: false
};
export default CarouselButtons;
