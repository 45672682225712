import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AnchorTag = styled.a`
  color: ${({ theme }) => theme.colors.Primary};
  &:hover {
    text-decoration: underline;
  }
`;

const Link = ({ children, external, ...rest }) => {
  return (
    <AnchorTag
      target={external ? '_blank' : null}
      rel={external ? 'noopener noreferrer' : null}
      {...rest}
    >
      {children}
    </AnchorTag>
  );
};

Link.propTypes = {
  children: PropTypes.any.isRequired,
  external: PropTypes.bool
};

Link.defaultProps = {
  external: false
};

export default Link;
