import React, { useState, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import headerSprite from '../../assets/header-sprite.svg';
import SvgComponent from '../SvgComponent';
import Typography from '../Typography';
import { isRequired, isValidMobile, isValidEmail } from '../../helpers/common';

const ParentDiv = styled.div`
  position: relative;
`;

const Div = styled.div`
  line-height: 48px;
`;

const Message = styled(Typography)`
  margin: 5px 0 0 0;
  top: 100%;
  color: ${({ theme }) => theme.colors.Error};
  ${({ classType }) => {
    switch (classType) {
      case 'error':
        return css`
          color: ${({ theme }) => theme.colors.Error};
        `;
      case 'success':
        return css`
          color: ${({ theme }) => theme.colors.Primary};
        `;
      case 'successMessage':
        return css`
          color: ${({ theme }) => theme.colors.Secondary};
        `;
      default:
        return css`
          color: ${({ theme }) => theme.colors.Primary};
        `;
    }
  }}
`;
const Input = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.TextGamma};
  box-shadow: none;
  border-radius: 4px;
  background: transparent;
  padding: 14px;
  @media screen and (max-width: 767px) {
    padding: 15px 14px;
  }
  color: ${({ theme }) => theme.colors.TextAlpha};
  &:invalid {
    outline: 0;
  }
  @media screen and (min-width: 767px) {
    &:hover {
      border-color: ${({ theme }) => theme.colors.TextBeta};
    }
  }
  margin: 1px 0px;
  &:focus {
    margin: 0px;
    border: 2px solid ${({ theme }) => theme.colors.Primary};
  }
  &[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
  &:valid ~ label,
  &:focus ~ label,
  &:disabled ~ label {
    font-size: 12px;
    top: -12px;
    background: #fff;
    color: ${({ theme }) => theme.colors.TextGamma};
    padding: 0px 5px;
    left: 12px;
    height: 22px;
    display: flex;
    align-items: center;
  }
  &:focus ~ label {
    color: ${({ theme }) => theme.colors.Primary};
  }
  ${({ classState }) => {
    switch (classState) {
      case 'error':
        return css`
          border-color: ${({ theme }) => theme.colors.Error};
          &:focus,
          &:valid {
            border-color: ${({ theme }) => theme.colors.Error};
          }
          &:valid ~ label,
          &:focus ~ label {
            color: ${({ theme }) => theme.colors.Error};
          }
        `;
      case 'success':
        return css`
          border-color: ${({ theme }) => theme.colors.Neutral[200]};
          &:valid {
            border-color: ${({ theme }) => theme.colors.Neutral[200]};
          }
          &:focus {
            border-color: ${({ theme }) => theme.colors.Primary};
          }
          &:valid ~ label {
            color: ${({ theme }) => theme.colors.TextGamma};
          }
          &:focus ~ label {
            color: ${({ theme }) => theme.colors.Primary};
          }
        `;
    }
  }}
  ${({ leftPadding }) =>
    leftPadding &&
    css`
      padding-left: 45px;
      @media (max-width: 767px) {
        padding-left: 45px;
      }
    `}
  ${({ rightPadding }) =>
    rightPadding &&
    css`
      padding-right: 45px;
      @media (max-width: 767px) {
        padding-right: 45px;
      }
    `}
  ${({ multiline }) =>
    multiline &&
    css`
      height: auto;
      resize: none;
    `}
`;
const LeftIcon = styled(SvgComponent)`
  height: 20px;
  width: 20px;
  left: 14px;
`;

const RightIcon = styled(SvgComponent)`
  height: 20px;
  width: 20px;
  right: 14px;
`;
const Label = styled.label`
  top: 0;
  left: 14px;
  color: ${({ theme }) => theme.colors.TextGamma};
  transition: 0.2s all;
  cursor: text;
  pointer-events: none;
  ${({ leftPadding }) =>
    leftPadding &&
    css`
      left: 46px;
    `}
  ${({ classState }) =>
    classState === 'error' &&
    css`
      color: ${({ theme }) => theme.colors.Error};
    `}
     ${({ classState }) =>
    classState === 'success' &&
    css`
      color: ${({ theme }) => theme.colors.TextGamma};
    `}
       @media(max-width: 767px) {
    font-size: 13px;
  }
`;

const Span = styled.span`
  ${({ classType }) => {
    switch (classType) {
      case 'left':
        return css`
          left: 14px;
        `;
      case 'right':
        return css`
          right: 14px;
        `;
    }
  }}
`;

const HelpText = styled.div`
  top: 100%;
  margin-top: 3px;
  color: ${({ theme }) => theme.colors.TextGamma};
`;

const TextField = props => {
  const { register, errors, setValue } = useFormContext() || {
    register: null,
    errors: null,
    setValue: null
  };
  const [error, setError] = useState(null);
  const [fieldState, setFieldState] = useState('');
  const [inputVal, setInputVal] = useState(props.value && props.value);
  const [showPlaceHolder, setPlaceHolder] = useState(true);

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current && props.setFocus && !inputRef.current.value) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const { field = '' } = props;
    if (register && field !== 'postfilled') {
      const {
        required,
        fieldType,
        reqValidationMessage,
        fieldValidationMessage,
        defaultReqMessage,
        allowBlankOnBlur,
        defaultValidationMessage
      } = props;
      let validation = false;

      if (required || fieldType === 'mobile' || fieldType === 'email') {
        validation = { validate: {} };

        if (required && !isRequired(value)) {
          validation.validate.required = value => {
            return value !== '' || reqValidationMessage || defaultReqMessage;
          };
        }

        if (fieldType === 'email') {
          validation.validate.isValidEmail = value => {
            return (
              (value !== '' && isValidEmail(value, allowBlankOnBlur)) ||
              fieldValidationMessage ||
              defaultValidationMessage
            );
          };
        }

        if (fieldType === 'mobile') {
          validation.validate.isValidMobile = value => {
            return (
              (value !== '' && isValidMobile(value)) ||
              fieldValidationMessage ||
              defaultValidationMessage
            );
          };
        }
      }

      register(inputRef.current, validation || props.validation);
    }
  }, [register]);

  const onBlur = e => {
    e.persist();
    const { value } = e.target;
    const {
      required,
      fieldType,
      reqValidationMessage,
      fieldValidationMessage,
      defaultReqMessage,
      allowBlankOnBlur,
      defaultValidationMessage
    } = props;
    if (!register && (required || fieldType === 'mobile' || fieldType === 'email')) {
      if (required && !isRequired(value)) {
        setError(reqValidationMessage || defaultReqMessage);
        setFieldState('error');
      } else if (
        (fieldType === 'email' && !isValidEmail(value, allowBlankOnBlur)) ||
        (fieldType === 'mobile' && !isValidMobile(value))
      ) {
        setError(fieldValidationMessage || defaultValidationMessage);
        setFieldState('error');
      } else {
        setError(null);
        setFieldState('success');
      }
    }
    setPlaceHolder(false);
    props.onBlur && props.onBlur(e, fieldState);
  };

  const onChange = (e, props = {}) => {
    const { value } = e.target;
    setInputVal(value);
    props.onUpdate && props.onUpdate(value);
    setValue &&
      setValue(inputName, value, {
        shouldValidate: true
      });
    if (props.handleChange) {
      props.handleChange(value);
    }
  };

  useEffect(() => {
    if (props.value !== inputVal) {
      setInputVal(props.value);
    }
  }, [props.value]);

  const {
    inputName,
    labelName,
    leftIcon,
    type,
    rightIcon,
    placeholder,
    infoMessage,
    maxLength,
    rightText,
    leftText,
    disabled,
    successMessage,
    fstate,
    value,
    autocomplete,
    multiline,
    rows,
    min,
    max,
    pattern,
    field,
    onBlur: onBlurChange, // to avoid setting onBlur on ParentDiv, we want to handle it in onBlur defined above
    ...rest
  } = props;

  const classState = fstate || fieldState;
  const showSuccessMessage = fieldState === 'success' && successMessage;
  const message = showSuccessMessage ? null : error || infoMessage;
  return (
    <ParentDiv {...rest}>
      <Div className="pR dFA w100">
        {leftIcon && <LeftIcon className="pA peN" svgSprite={headerSprite} id={leftIcon} />}
        {leftText && (
          <Span className="pA peN" classType="left">
            {leftText}
          </Span>
        )}
        {rightText && (
          <Span className="pA t0 peN" classType="right">
            {rightText}
          </Span>
        )}
        {rightIcon && <RightIcon className="pA peN" svgSprite={headerSprite} id={rightIcon} />}
        {register && field !== 'postfilled' ? (
          <Input
            as={multiline ? 'textarea' : 'input'}
            className="w100 outN"
            type={type}
            maxLength={maxLength}
            min={min}
            max={max}
            classState={classState}
            name={inputName}
            onBlur={onBlur}
            required
            onChange={onChange}
            disabled={disabled}
            leftPadding={!!(leftIcon || leftText)}
            rightPadding={!!(rightText || rightIcon)}
            placeholder={showPlaceHolder ? placeholder : null}
            defaultValue={inputVal}
            autoComplete={autocomplete}
            multiline={multiline}
            rows={rows}
            ref={inputRef}
          />
        ) : (
          <Input
            as={multiline ? 'textarea' : 'input'}
            className="w100 outN"
            type={type}
            maxLength={maxLength}
            min={min}
            max={max}
            classState={classState}
            name={inputName}
            onBlur={onBlur}
            required
            onChange={e => onChange(e, props)}
            disabled={disabled}
            leftPadding={!!(leftIcon || leftText)}
            rightPadding={!!(rightText || rightIcon)}
            placeholder={showPlaceHolder ? placeholder : null}
            value={inputVal}
            autoComplete={autocomplete}
            multiline={multiline}
            rows={rows}
            ref={inputRef}
          />
        )}
        <Label
          className="pA"
          htmlFor={inputName}
          leftPadding={!!(leftIcon || leftText)}
          classState={classState}
        >
          {labelName}
          {props.required || (props.validation && props.validation.required) ? '*' : ''}
        </Label>
      </Div>
      {message && (
        <Message tagName="p" modifiers="bt3" className="pA" classType={classState}>
          {message}
        </Message>
      )}
      {errors && errors[inputName] && (
        <Message tagName="p" modifiers="bt3" className="pA" classType="error">
          {errors[inputName].message}
        </Message>
      )}
      {showSuccessMessage && (
        <Message tagName="p" modifiers="bt3" className="pA" classType="successMessage">
          {successMessage}
        </Message>
      )}

      {props.helpText !== '' && !errors[inputName] && (
        <HelpText className="pA l0 bt3">{props.helpText}</HelpText>
      )}
    </ParentDiv>
  );
};

TextField.propTypes = {
  inputName: PropTypes.string,
  leftIcon: PropTypes.string,
  required: PropTypes.bool,
  labelName: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'tel', 'date', 'email']),
  validationMessage: PropTypes.string,
  maxLength: PropTypes.number,
  min: PropTypes.string,
  max: PropTypes.string,
  onBlur: PropTypes.func,
  onUpdate: PropTypes.func,
  leftText: PropTypes.node,
  rightText: PropTypes.string,
  rightIcon: PropTypes.string,
  disabled: PropTypes.bool,
  successMessage: PropTypes.string,
  defaultReqMessage: PropTypes.string,
  fieldType: PropTypes.string,
  reqValidationMessage: PropTypes.string,
  fieldValidationMessage: PropTypes.string,
  allowBlankOnBlur: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  infoMessage: PropTypes.string,
  fstate: PropTypes.string,
  defaultValidationMessage: PropTypes.string,
  autocomplete: PropTypes.string,
  setFocus: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  validation: PropTypes.object,
  helpText: PropTypes.string,
  pattern: PropTypes.string,
  field: PropTypes.string,
  handleChange: PropTypes.func
};

TextField.defaultProps = {
  defaultReqMessage: 'This field is required',
  defaultValidationMessage: 'Invalid Type',
  multiline: false,
  rows: 1,
  validation: {},
  helpText: ''
};

export default TextField;
