export const Ellipsis = (string, from, maxLength) => {
  const FinalString = string.substring(from, maxLength - 3);
  if (maxLength >= string.length) {
    return string;
  }
  return `${FinalString.trim()}...  `;
};

export const extractImgUrl = (image, type) => {
  if (image && image[type]) {
    return image[type];
  } else if (image.lg) {
    return image.lg;
  } else if (image.nr) {
    return image.nr;
  } else if (image.gv) {
    return image.gv;
  } else if (image.sm) {
    return image.sm;
  } else if (image.sq) {
    return image.sq;
  }
  return image;
};

export const convertPriceToIndFormat = value => {
  if (typeof value === 'undefined') {
    return 0;
  }
  const price = value.toString();
  let lastThree = price.substring(price.length - 3);
  const otherNumbers = price.substring(0, price.length - 3);
  if (otherNumbers !== '') {
    lastThree = ',' + lastThree;
  }
  const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',');
  return res + lastThree;
};

export const transformKeysInObj = obj => {
  if (obj) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        if (obj[key] != null && typeof obj[key] === 'object') {
          return [replaceUnderScoreWithSpace(key), transformKeysInObj(obj[key])];
        }
        return [replaceUnderScoreWithSpace(key), value];
      })
    );
  }
  return null;
};

export const replaceUnderScoreWithSpace = value => {
  return `${value
    .replace(/(^|_)./g, s => s.toUpperCase())
    .split('_')
    .join(' ')}`;
};
export const isValidMobile = mobile => {
  if (!mobile || !/^[0][6-9]\d{9}$|^[6-9]\d{9}$/.test(mobile)) {
    return false;
  }
  return true;
};

export const isRequired = inputVal => {
  if (!inputVal || inputVal === '' || (Array.isArray(inputVal) && inputVal.length <= 0)) {
    return false;
  }
  return true;
};

export const isValidEmail = (inputVal, allowEmpty) => {
  if (allowEmpty && inputVal === '') {
    return true;
  }
  if (!inputVal || !/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(inputVal)) {
    return false;
  }
  return true;
};

export const getWhatsappHref = (number, adTitle) => {
  const whatsappText = 'Hi, I am interested in your ' + adTitle + '\n' + window.location.href;
  return `https://wa.me/91${number}/?text=${encodeURIComponent(whatsappText)}`;
};

export const easeInOutQuad = t => {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
};

export const checkForIntersectionObserver = () => {
  if (!('IntersectionObserver' in window) || !('IntersectionObserverEntry' in window)) {
    return false;
  }
  return true;
};

export const stringReplacer = (str, selector, replacer) => {
  if (!str || typeof str !== 'string') {
    return null;
  }
  return str.split(selector).join(replacer);
};

export const interceptEvent = event => {
  if (event) {
    if (event.preventDefault) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
};

export const getLocalStorage = key => {
  const item = window.localStorage.getItem(key);
  try {
    return item ? JSON.parse(item) : null;
  } catch (error) {
    return item;
  }
};

export const getSessionStorage = key => {
  const item = window.sessionStorage.getItem(key);
  try {
    return item ? JSON.parse(item) : null;
  } catch (error) {
    return item;
  }
};

export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const setSessionStorage = (key, value) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

export const getQueryParam = key => {
  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get(key)) {
      return searchParams.get(key);
    }
  }
  return false;
};

export const priceChecker = price => {
  if (+price < 1000000) {
    return convertPriceToIndFormat(price);
  }
  let finalPrice =
    +price < 10000000 ? String((price / 100000).toFixed(2)) : String((price / 10000000).toFixed(2));
  finalPrice = finalPrice.split('.')[1] === '00' ? finalPrice.split('.')[0] : finalPrice;
  const plural = finalPrice !== '1';
  finalPrice += +price < 10000000 ? ' Lakh' : ' Crore';
  finalPrice += plural ? 's' : '';
  return finalPrice;
};

export const throttle = (func, threshold) => {
  let inThrottle;
  return function throttled (...args) {
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => {
        inThrottle = false;
      }, threshold);
    }
  };
};

export const moneyFormat = {
  ones: ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'],
  tens: ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'],
  teens: [
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen'
  ],

  convertCrores: num => {
    if (num >= 10000000) {
      return `${moneyFormat.convertCrores(
        Math.floor(num / 10000000)
      )} Crore ${moneyFormat.convertLakhs(num % 10000000)}`;
    } else return moneyFormat.convertLakhs(num);
  },

  convertLakhs: num => {
    if (num >= 100000) {
      return `${moneyFormat.convertLakhs(
        Math.floor(num / 100000)
      )} Lakh ${moneyFormat.convertThousands(num % 100000)}`;
    } else return moneyFormat.convertThousands(num);
  },

  convertThousands: num => {
    if (num >= 1000) {
      return `${moneyFormat.convertHundreds(
        Math.floor(num / 1000)
      )} Thousand ${moneyFormat.convertHundreds(num % 1000)}`;
    } else return moneyFormat.convertHundreds(num);
  },

  convertHundreds: num => {
    if (num > 99) {
      return `${moneyFormat.ones[Math.floor(num / 100)]} Hundred ${moneyFormat.convertTens(
        num % 100
      )}`;
    } else return moneyFormat.convertTens(num);
  },

  convertTens: num => {
    if (num < 10) return moneyFormat.ones[num];
    else if (num >= 10 && num < 20) return moneyFormat.teens[num - 10];
    else return moneyFormat.tens[Math.floor(num / 10)] + ' ' + moneyFormat.ones[num % 10];
  },

  convert: num => {
    if (num === '' || num === undefined || isNaN(num)) return '';
    else if (num === '0') return 'Zero';
    else return moneyFormat.convertCrores(num);
  }
};

export const apiWrapper = async (
  { url, method = 'get', headers, body = {}, credentials },
  useAxios = false
) => {
  if (!useAxios && typeof fetch === 'function') {
    const payload = {
      method,
      headers,
      credentials
    };
    if (method.toLowerCase() === 'post') {
      payload.body = JSON.stringify(body);
    }
    const response = await fetch(url, payload);
    return response.json();
  } else {
    const axios = await import('axios');
    const data = body;
    const response =
      method.toLowerCase() === 'post' ? axios.post(url, data, headers) : axios.get(url, headers);
    const json = await response;
    return json.data;
  }
};

export const queryString = queryObject => {
  if (typeof queryObject === 'object') {
    const keys = Object.keys(queryObject);
    if (keys.length > 0) {
      return `?${keys
        .reduce((accumulator, each) => {
          accumulator.push(`${each}=${queryObject[each]}`);
          return accumulator;
        }, [])
        .join('&')}`;
    }
  }
  return '';
};

export const returnUsableUser = user => {
  const obj = Object.assign({}, user);
  Object.keys(obj).forEach(key => {
    if (obj[key] === undefined || ['password', 'name'].includes(key)) {
      delete obj[key];
    } else {
      if (['id'].includes(key)) {
        obj[key] = parseInt(obj[key]);
      }
    }
  });
  return obj;
};
