import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const QCarousel = styled.div`
  height: ${props => props.itemHeight + 'px'};
  @media screen and (min-width: 400px) {
    height: ${props => props.itemHeight + 28 + 'px'};
  }
`;

const QCarouselWrapper = styled.div`
  -webkit-overflow-scrolling: touch;
  height: ${props => props.itemHeight + 15 + 'px'};
  @media screen and (min-width: 400px) {
    height: ${props => props.itemHeight + 43 + 'px'};
  }
`;

const MobileCarousel = props => {
  const { children, itemHeight, onClick, className, ...rest } = props;
  return (
    <QCarousel
      className={`oH ${className || ''}`}
      onClick={onClick}
      itemHeight={itemHeight}
      {...rest}
    >
      <QCarouselWrapper className="dF oxA oyH" itemHeight={itemHeight}>
        {children}
      </QCarouselWrapper>
    </QCarousel>
  );
};

MobileCarousel.propTypes = {
  children: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  itemHeight: PropTypes.number,
  className: PropTypes.string
};

MobileCarousel.defaultProps = {
  alt: '',
  itemHeight: 215
};

export default MobileCarousel;
