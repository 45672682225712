import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
const FlexContainer = styled.div`
  max-width: 1230px;
  margin: 0px auto;
`;

const Container = ({ children, className, ...rest }) => {
  return (
    <FlexContainer className={`pR ${className || ''}`} {...rest}>
      {children}
    </FlexContainer>
  );
};

Container.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

export default Container;
