import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import SvgComponent from '../SvgComponent';
import Container from '../FlexBox/Container';
import Typography from '../Typography';
import headerSprite from '../../assets/header-sprite.svg';

const TopBar = styled.div`
  background: ${({ theme }) => theme.colors.Neutral[500]};
  z-index: 1;
  padding: 0 12px;
`;
const Ul = styled.ul`
  height: 32px;
  ${({ className }) =>
    className === 'float' &&
    css`
      margin-left: auto;
    `}
`;
const Li = styled.li`
  margin: 0 30px 0 0;
  &:last-child {
    margin: 0;
  }
  span {
    display: flex;
    margin: 0 10px 0 0;
  }
  svg {
    width: 17px;
    height: 17px;
  }
`;
const Link = styled(Typography)`
  color: ${({ theme }) => theme.colors.TextAlpha};
`;
const HeaderTopLinks = () => {
  return (
    <TopBar className="pR">
      <Container className="dFA jcSB">
        <Ul className="dFA">
          <Li className="dF">
            <Link
              tagName="a"
              modifiers="bt2"
              className="dFA"
              href="https://www.quikr.com/Business?action=businessPage"
            >
              <span>
                <SvgComponent svgSprite={headerSprite} id="ic_for_business"></SvgComponent>
              </span>
              For Businessess
            </Link>
          </Li>
          <Li className="dF">
            <Link tagName="a" modifiers="bt2" className="dFA" href="http://www.quikr.com/app">
              <span>
                <SvgComponent svgSprite={headerSprite} id="ic_download_app"></SvgComponent>
              </span>
              Download the App
            </Link>
          </Li>
        </Ul>
        <Ul className="dFA float">
          <Li className="dF">
            <Link
              tagName="a"
              modifiers="bt2"
              className="dFA"
              href="https://www.quikr.com/MyQuikr?action=mychats"
            >
              <span>
                <SvgComponent svgSprite={headerSprite} id="ic_chat"></SvgComponent>
              </span>
              Chat
            </Link>
          </Li>
        </Ul>
      </Container>
    </TopBar>
  );
};

HeaderTopLinks.propTypes = {
  children: PropTypes.any
};

export default HeaderTopLinks;
