import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Typography from '../Typography';

const Breadcrumbs = styled.ul`
  margin: 0 0 16px;
  @media screen and (max-width: 768px) {
    margin-top: 15px;
  }
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.Primary};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  ${({ type }) =>
    type === 'last' &&
    css`
      color: ${({ theme }) => theme.colors.TextAlpha};
    `}
`;

const List = styled(Typography)`
  &:after {
    content: '/';
    color: ${({ theme }) => theme.colors.Primary};
    margin: 0 5px;
  }
  &:last-child {
    &:after {
      display: none;
    }
    a {
      width: 250px;
      @media screen and (max-width: 768px) {
        max-width: 280px;
      }
    }
    &:nth-child(n + 6) {
      a {
        width: 100px;
      }
    }
  }
`;

const BreadcrumbsLinks = ({ data, url }) => {
  const { pathname, search } = useLocation();
  return (
    <Breadcrumbs className="dF fwW" itemScope itemType="https://schema.org/BreadcrumbList">
      {data &&
        data.map((menuData, i) =>
          menuData.href ? (
            <List
              className="dFA"
              modifiers="bt3"
              tagName="li"
              key={i}
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
            >
              <Link itemProp="item" href={menuData.href}>
                <span itemProp="name">{menuData.label}</span>
              </Link>
              <meta itemProp="position" content={i} />
            </List>
          ) : (
            <List
              className="dFA"
              modifiers="bt3"
              tagName="li"
              key={i}
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              title={menuData.label}
            >
              <Link itemProp="item" type="last" className="Ell" href={url || pathname + search}>
                <span itemProp="name">{menuData.label}</span>
              </Link>
              <meta itemProp="position" content={i} />
            </List>
          )
        )}
    </Breadcrumbs>
  );
};

BreadcrumbsLinks.propTypes = {
  data: PropTypes.array.isRequired,
  url: PropTypes.string
};

export default BreadcrumbsLinks;
