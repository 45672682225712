import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { stringReplacer } from '../../helpers/common';

const Label = styled.span`
  color: #fff;
  line-height: 1;
  padding: 6px 12px;
  white-space: nowrap;
  ${({ type }) => {
    switch (type) {
      case 'PREMIUM':
      case 'PREMIUM_URGENT':
      case 'PREFERRED_SELLER':
        return css`
          background: ${({ theme }) => theme.colors.Secondary};
        `;
      case 'GOLD':
      case 'GOLD_URGENT':
        return css`
          background: ${({ theme }) => theme.colors.gold};
        `;
      case 'PLATINUM':
        return css`
          background: ${({ theme }) => theme.colors.platinum};
        `;
      case 'SPOTLIGHT':
        return css`
          background: #7600d0;
        `;
      default:
    }
  }}
  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 14px;
    `}
  ${({ cornerRounded }) =>
    cornerRounded &&
    css`
      border-bottom-right-radius: 10px;
    `}
`;

const Tag = props => {
  const { type, className, ...rest } = props;
  const replacer = type === 'PREFERRED_SELLER' ? ' ' : ' + ';
  return (
    <Label
      tagName="span"
      modifiers={['bt2', 'medium']}
      className={`dIB ttU ${className || ''}`}
      type={type}
      {...rest}
    >
      {stringReplacer(type, '_', replacer)}
    </Label>
  );
};

Tag.propTypes = {
  type: PropTypes.oneOf([
    'BASIC',
    'GOLD',
    'PLATINUM',
    'PREMIUM',
    'SPOTLIGHT',
    'PREMIUM_URGENT',
    'GOLD_URGENT',
    'PREFERRED_SELLER'
  ]),
  rounded: PropTypes.bool,
  cornerRounded: PropTypes.bool,
  className: PropTypes.string
};

Tag.defaultProps = {
  rounded: false,
  cornerRounded: false
};

export default Tag;
