import React, { useState, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Typography } from '@quikr/ui';
import Checkbox from '../Checkbox';
import Button from '../Button';
import Close from '../../icons/Close';
import Search from '../../icons/Search';
import { interceptEvent } from '../../helpers/common';
import DownArrow from '../../icons/DownArrow';

// Key handlers
const keyHandlers = {
  38: 'handleUpKey',
  40: 'handleDownKey',
  32: 'handleSpaceKey',
  13: 'handleEnterKey',
  27: 'handleEscKey',
  74: 'handleDownKey',
  75: 'handleUpKey'
};

const Div = styled.div`
  margin: 20px 0px;
  width: 100%;
  border-radius: 4px;
  line-height: 46px;
  border: 1px solid ${({ theme }) => theme.colors.TextGamma};
  @media screen and (min-width: 767px) {
    &:hover {
      border-color: ${({ theme }) => theme.colors.TextBeta};
    }
  }
  &:focus-within {
    border: 2px solid ${({ theme }) => theme.colors.Primary};
    > input {
      margin: 0px;
    }
  }
`;
const CloseT = styled(Close)`
  width: 16px;
  fill: ${({ theme }) => theme.colors.TextBeta};
  z-index: 1;
`;
const Input = styled.input`
  box-shadow: none;
  background: transparent;
  padding: 15px 14px;
  color: ${({ theme }) => theme.colors.TextAlpha};
  margin: 1px 0px;
  &:invalid {
    outline: 0;
  }
  label {
    outline: none;
  }
  ${({ inputLabelUp, multiple }) =>
    inputLabelUp &&
    css`
      ~ label {
        font-size: 12px;
        top: -12px;
        left: 12px;
        height: 22px;
        padding: 0px 5px;
        color: ${({ theme }) => theme.colors.Primary};
        background: #fff;
        margin-right: 10px;
      }
    `}
  &:valid ~ label,
  &:focus ~ label {
    font-size: 12px;
    top: -12px;
    z-index: 1;
    background: #fff;
    color: ${({ theme }) => theme.colors.Primary};
    padding: 0px 5px;
    left: 12px;
    height: 22px;
    margin-right: 10px;
  }
  ${({ borderError }) => {
    switch (borderError) {
      case 'error':
        return css`
          &:focus,
          &:valid {
            border-color: ${({ theme }) => theme.colors.Error};
          }
          &:focus,
          &:valid ~ label {
            color: ${({ theme }) => theme.colors.Error};
          }
        `;
      case 'success':
        return css`
          &:focus,
          &:valid {
            border-color: ${({ theme }) => theme.colors.Neutral[200]};
          }
          &:focus,
          &:valid ~ label {
            color: ${({ theme }) => theme.colors.TextGamma};
          }
        `;
    }
  }}
`;

const Label = styled.label`
  position: absolute;
  top: 0;
  outline: none;
  left: 14px;
  pointer-events: none;
  color: ${({ theme }) => theme.colors.TextGamma};
  transition: 0.2s all;
  cursor: text;
  display: flex;
  align-items: center;
  line-height: 1;
  height: 46px;
  padding-right: 15px;
  ${({ className }) =>
    className === 'error' &&
    css`
      color: ${({ theme }) => theme.colors.Error};
    `}
  ${({ className }) =>
    className === 'success' &&
    css`
      color: ${({ theme }) => theme.colors.TextGamma} !important;
    `}
  @media(max-width: 767px) {
    font-size: 13px;
  }
`;
const Options = styled.ul`
  background: #fff;
  border-radius: 4px;
  overflow-y: auto;
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 9;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1), 0 8px 8px 0 rgba(0, 0, 0, 0.05);
  max-height: 220px;
  min-height: 50px;
  ${({ open }) =>
    open &&
    css`
      display: block;
    `}
  @media (max-width: 767px) {
    position: fixed;
    box-shadow: none;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 10px 10px 0 0;
    min-height: 100%;
    padding: 55px 0 0;
    ${({ checkHeight, len, isMultiple, checkLabels }) =>
      checkHeight &&
      len &&
      css`
        bottom: 0;
        top: inherit;
        padding: ${isMultiple ? '0 0 55px' : '0'};
      `}
  }
`;
const OptionsLi = styled.li`
  padding: 0px 15px;
  &:hover {
    transition: all 0.3s ease-in 0s;
    background: ${({ theme }) => theme.colors.Neutral[400]};
  }

  ${({ focused }) =>
    focused &&
    css`
      background: ${({ theme }) => theme.colors.Neutral[400]};
      color: ${({ theme }) => theme.colors.TextAlpha};
      transition: all 0.3s ease-in 0s;
    `}
  span {
    margin-top: 2px;
  }
`;
const CheckBoxx = styled(Checkbox)`
  span {
    &:before {
      margin: 2px 11px 3px 1px;
    }
  }
  ${({ checked }) =>
    checked &&
    css`
      color: ${({ theme }) => theme.colors.Primary};
      font-weight: bold;
    `};
`;
const TextSpan = styled.span`
  margin-top: 2px;

  ${({ checked }) =>
    checked &&
    css`
      color: ${({ theme }) => theme.colors.Primary};
      font-weight: bold;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 15px;
        right: 20px;
        width: 5px;
        height: 11px;
        border: solid ${({ theme }) => theme.colors.Primary};
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    `};
`;
const ValueSpanParent = styled.ul`
  ${({ isMultiple }) =>
    isMultiple
      ? css`
          display: flex;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;
          margin-left: 15px;
          margin-bottom: 10px;
          position: relative;
          z-index: 1;
        `
      : css`
          position: absolute;
          top: 0px;
          left: 14px;
          z-index: -1;
        `}
`;
const ValueSpan = styled.li`
  margin: 10px 0px 0px;
  span {
    display: none;
  }
  ${({ multiple }) =>
    multiple &&
    css`
      font-weight: normal;
      overflow: hidden;
      cursor: pointer;
      background: ${({ theme }) => theme.colors.Neutral[300]};
      padding: 0px 0px 0px 10px;
      display: inline-block;
      margin-right: 12px;
      border-radius: 17px;
      white-space: nowrap;
      outline: none;
      text-overflow: ellipsis;
      line-height: 28px;
      font-size: 12px;
      span {
        display: inline-flex;
      }
    `}
`;
const Cross = styled(Typography)`
  background: ${({ theme }) => theme.colors.Neutral[100]};
  height: 24px;
  width: 24px;
  z-index: 2;
  padding: 3px;
  vertical-align: middle;
  margin-left: 5px;
  top: -1px;
  right: 2px;
`;
const SpanVal = styled.span`
  pointer-events: none;
  left: 14px;
`;
const DownArrowIcon = styled(DownArrow)`
  width: 30px;
  top: 8px;
  fill: ${({ theme }) => theme.colors.TextGamma};
`;

const ButtonFixed = styled(Button)`
  bottom: 10px;
  width: 90%;
  margin: auto;
  height: 40px;
`;
const InputMobile = styled.input`
  height: 40px;
`;
const DivMobileWrapper = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
  display: flex;
  align-items: center;
  background: #fff;
  z-index: 9;
  justify-content: flex-start;
  border: 1px solid ${({ theme }) => theme.colors.Neutral[200]};
  height: 48px;
  border-radius: 10px 10px 0px 0px;
  position: fixed;
  span {
    height: 48px;
    width: 48px;
    text-align: center;
    line-height: 46px;
  }
  ${({ checkHeight }) =>
    checkHeight &&
    css`
      position: relative;
    `}
`;
const LayoutDiv = styled.div`
  background: rgba(0, 0, 0, 0.75);
  z-index: 8;
`;
const MspanClose = styled.span`
  color: #000;
`;
const Ptext = styled(Typography)`
  text-align: left;
  margin: 0px;
  color: ${({ theme }) => theme.colors.TextAlpha};
  line-height: 1.3;
`;
const SearchT = styled(Search)`
  right: 10px;
`;
const ErrorText = styled(Typography)`
  bottom: -20px;
  color: ${({ theme }) => theme.colors.Error};
  line-height: 1;
`;

const Select = props => {
  const {
    selectedVal,
    updateVal,
    noNullValues,
    onChange,
    closeOnChange,
    multiple,
    name = '',
    valueKey,
    items,
    allNoneValues,
    onAutoComplete,
    labelKey,
    searchable,
    readOnly,
    isMobile,
    disabled,
    labelText,
    error,
    throttle,
    minLength,
    isRequired,
    validation
  } = props;
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(selectedVal || []);
  const [searchText, setSearchText] = useState('');
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [timeoutHandle, setTimeoutHandle] = useState(null);
  const { errors, register, setValue, clearErrors } = useFormContext() || {
    errors: null,
    register: null,
    setValue: null,
    clearErrors: null
  };

  let _selectInput;
  const node = useRef(null);

  const filterItems = () => {
    const filterValue = (searchText || '').replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    const disableFilter = onAutoComplete !== null;
    if (filterValue && !disableFilter) {
      return (items || []).filter(
        option =>
          option[labelKey]
            .replace(/[^a-zA-Z0-9]/g, '')
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) >= 0
      );
    }
    return items;
  };
  const renderValues = () => {
    const selcted = values || [];
    let lables = [];
    if (multiple && selcted.length) {
      lables = (items || []).filter(option => selcted.indexOf(option[valueKey].toString()) >= 0);
    } else {
      lables = (items || []).filter(
        option => option[valueKey] && option[valueKey].toString() === selcted
      );
    }
    const remainingLen = selcted.length - 2;
    return lables.length
      ? `${lables
          .slice(0, 2)
          .map(data => data[labelKey])
          .join(', ')} ${multiple && remainingLen > 0 ? `+ ${remainingLen} More` : ''}`
      : null;
  };
  const visibleOptions = filterItems();
  const isRenderVal = !!renderValues();

  useEffect(() => {
    if (register && name) {
      register({ name }, validation);
    }
  }, [register, name]);

  useEffect(() => {
    if (updateVal === undefined) {
      return;
    }
    setValues(updateVal);
    // Set selected value for hook form on re-render
    setValue && setValue(name, updateVal, { shouldDirty: !(updateVal === '') });
  }, [updateVal]);

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClick, false);
    }
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, [open]);

  useEffect(() => {
    onAutoComplete && handleAutoComplete(searchText);
  }, [searchText]);

  const value = () => {
    if (Array.isArray(values)) {
      return values;
    } else if (values != null) {
      return [values];
    }
    return [];
  };
  const updateValues = values => {
    if (values || !noNullValues) {
      if (!multiple) {
        _selectInput.blur();
        if (closeOnChange) {
          setOpen(false);
        }
      }
      setFocusedIndex(null);
      setSearchText('');
      setValues(values);
      // Set value for hook form context
      setValue && setValue(name, values, { shouldDirty: true });
      // Clear validation error messages
      clearErrors && clearErrors(name);
      onChange && onChange(values, name);
    }
  };
  const handleAutoComplete = query => {
    if (query.length < minLength) {
      return;
    }
    if (timeoutHandle) {
      clearTimeout(timeoutHandle);
    }
    setTimeoutHandle(
      setTimeout(() => {
        onAutoComplete(query);
      }, throttle)
    );
  };
  const handleClose = event => {
    interceptEvent(event);
    if (open) {
      setOpen(false);
      setFocusedIndex(null);
      setSearchText('');
    }
  };
  const handleClick = e => {
    if (node.current && node.current.contains(e.target)) return;
    handleClose();
  };
  const handleSelect = (event, val) => {
    setSearchText('');
    interceptEvent(event);
    let selectedValue = val && val[valueKey] && val[valueKey].toString();
    if (!selectedValue) {
      selectedValue = event.target.getAttribute('data-value');
      if (selectedValue) selectedValue = selectedValue.toString();
    }
    if (selectedValue === null) return;
    if (multiple) {
      let selected = [];
      if (selectedValue != null && selectedValue !== '') {
        selected = handleAllNone(selectedValue);
        const index = selected.indexOf(selectedValue);
        if (index !== -1) {
          selected.splice(index, 1);
        } else {
          selected.push(selectedValue);
        }
      }
      selected = selected.length > 0 ? selected : undefined;
      updateValues(selected);
    } else if (selectedValue && value()[0] === selectedValue) {
      updateValues(null);
    } else {
      updateValues(selectedValue);
    }
  };
  const handleAllNone = selectedValue => {
    let selected = value().slice(0);
    if (allNoneValues.length > 0) {
      const isAny = (items || []).filter(
        item => allNoneValues.indexOf(item.valueName.toLowerCase()) > -1
      );
      if (isAny.length > 0) {
        const isAnyValue = isAny[0][valueKey].toString();
        const anyIndex = selected.indexOf(isAnyValue);
        if (anyIndex !== -1) {
          selected.splice(anyIndex, 1);
        }
        selected = isAnyValue === selectedValue ? [] : selected;
      }
    }
    return selected;
  };
  const handleOpen = () => {
    !open && setOpen(true);
  };
  const handleFilter = event => {
    interceptEvent(event);
    const query = event.target.value;
    !open && setOpen(true);
    setSearchText(query);
  };
  const handleInputFocus = event => {
    interceptEvent(event);
    handleOpen();
  };
  const handleMouseDown = event => {
    searchable && handleOpen(event);
  };
  const keyHandlerActions = {
    handleUpKey: () => {
      moveIndex(-1);
    },
    handleDownKey: () => {
      moveIndex(1);
    },
    handleSpaceKey: event => {
      handleOpen();
      if (multiple) {
        keyHandlerActions.handleEnterKey(event);
      }
    },
    handleEnterKey: event => {
      if (focusedIndex != null) {
        handleSelect(event, visibleOptions[focusedIndex]);
      }
    },
    handleEscKey: event => {
      handleClose(event);
    }
  };
  const handleKeyDown = event => {
    if (keyHandlers[event.which]) {
      keyHandlerActions[keyHandlers[event.which]](event);
    }
  };
  const moveIndex = move => {
    const len = visibleOptions.length;
    const _focusedIndex = focusedIndex === null ? -1 : focusedIndex;
    const idx = (_focusedIndex + move + len) % len;
    setFocusedIndex(idx);
    setOpen(true);
  };

  const renderInput = () => {
    return (() => {
      return (
        <Input
          className="w100 boN outN pR"
          readOnly={readOnly}
          ref={ref => {
            _selectInput = ref;
          }}
          required
          type="text"
          onChange={handleFilter}
          name="query"
          multiple={multiple}
          value={searchText}
          placeholder={multiple && open && !!renderValues() ? 'Search' : ''}
          onFocus={handleInputFocus}
          onMouseDown={handleMouseDown}
          autoComplete="off"
          disabled={disabled}
          inputLabelUp={!!renderValues()}
        />
      );
    })();
  };

  const renderInputMobile = () => {
    return (() => {
      return (
        <InputMobile
          className="boN outN w100"
          placeholder="Search"
          type="text"
          onFocus={handleInputFocus}
          onChange={handleFilter}
          value={searchText}
        />
      );
    })();
  };

  const renderOptions = () => {
    return (visibleOptions || []).map((item, index) => {
      let isSelected = false;
      if (item && item[valueKey]) {
        isSelected = value().slice(0).indexOf(item[valueKey].toString()) > -1;
      }
      return (
        <OptionsLi
          className="pR cP"
          key={index}
          focused={index === focusedIndex}
          data-value={item[valueKey]}
          onMouseDown={handleMouseDown}
          onClick={handleSelect}
        >
          {multiple ? (
            <CheckBoxx
              skipRegister={true}
              checked={isSelected}
              dataVal={item[valueKey]}
              value={item[labelKey]}
            />
          ) : (
            <TextSpan checked={isSelected} data-value={item[valueKey]}>
              {item[labelKey]}
            </TextSpan>
          )}
        </OptionsLi>
      );
    });
  };

  const renderLabels = () => {
    return (() => {
      const selcted = values;
      let lables;
      if ((selcted || []).length) {
        lables = (items || []).filter(option => selcted.indexOf(option[valueKey].toString()) >= 0);
      }

      return (
        <ValueSpanParent isMultiple={multiple} id="displayContent">
          {(lables || []).map((item, index) => {
            return (
              <ValueSpan key={index} tabIndex="-1" multiple={multiple}>
                {item[labelKey]}
                <Cross
                  tagName="span"
                  modifiers="bt3"
                  className="pR dFA dIF jcC br50"
                  data-value={item[valueKey]}
                  id="cross"
                  onClick={event => {
                    handleSelect(event);
                    handleClick(event);
                  }}
                >
                  X
                </Cross>
              </ValueSpan>
            );
          })}
        </ValueSpanParent>
      );
    })();
  };

  const renderApply = () => {
    return (() => {
      return (
        <ButtonFixed
          className="pF l0 r0"
          btnType="primary"
          onClick={handleApply}
          size="lg"
          type="button"
        >
          DONE
        </ButtonFixed>
      );
    })();
  };

  const handleApply = e => {
    interceptEvent(e);
    handleClose(e);
  };

  const shouldRenderInputMobile = () => {
    return items.length > 6 || onAutoComplete !== null;
  };

  return (
    <>
      {isMobile && open && <LayoutDiv className="pF t0 l0 w100 h100" />}
      <Div
        className="pR dF fdC outN"
        onKeyDown={handleKeyDown}
        onMouseDown={handleMouseDown}
        tabIndex="-1"
        id="sD"
        isMobile={isMobile}
        checkHeight={isRenderVal}
        ref={node}
        focused={open}
        selected={value().length !== 0}
      >
        {renderInput()}
        <Label className={!open && 'success'} tabIndex="-1">
          {labelText}
          {isRequired ? '*' : ''}
        </Label>
        {!open && isRenderVal && <SpanVal className="pA t0">{renderValues()}</SpanVal>}
        {open && multiple && !isMobile && renderLabels()}
        {(visibleOptions.length > 0 || isMobile) && (
          <Options
            isMultiple={multiple}
            open={open}
            checkHeight={!shouldRenderInputMobile()}
            len={items.length}
            checkLabels={isRenderVal}
          >
            {isMobile && (
              <DivMobileWrapper className="w100 t0" checkHeight={!shouldRenderInputMobile()}>
                <MspanClose className="cP dFA jcC" onClick={handleApply}>
                  <CloseT />
                </MspanClose>
                {shouldRenderInputMobile() && <SearchT className="pA" />}
                {shouldRenderInputMobile() ? (
                  renderInputMobile()
                ) : (
                  <Ptext tagName="p" modifiers="bold">
                    {labelText}
                  </Ptext>
                )}
              </DivMobileWrapper>
            )}
            {isMobile && multiple && renderLabels()}
            {renderOptions()}
            {isMobile && multiple && renderApply()}
          </Options>
        )}
        {error && (
          <ErrorText tagName="span" modifiers="bt3" className="pA">
            {error}
          </ErrorText>
        )}
        {errors &&
          (name && name.includes('.')
            ? errors[name.split('.')[0]] && errors[name.split('.')[0]][name.split('.')[1]]
            : errors[name]) && (
          <ErrorText tagName="span" modifiers="bt3" className="pA">
            {name && name.includes('.')
              ? errors[name.split('.')[0]] &&
                  errors[name.split('.')[0]][name.split('.')[1]].message
              : errors[name].message}
          </ErrorText>
        )}
        {!open && <DownArrowIcon className="pA peN r0" />}
      </Div>
    </>
  );
};

Select.propTypes = {
  throttle: PropTypes.number,
  minLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  closeOnChange: PropTypes.bool,
  items: PropTypes.array.isRequired,
  labelKey: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onAutoComplete: PropTypes.func,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  allNoneValues: PropTypes.array,
  selectedVal: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  updateVal: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  value: PropTypes.any,
  valueKey: PropTypes.string,
  labelText: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  noNullValues: PropTypes.bool,
  readOnly: PropTypes.bool,
  isMobile: PropTypes.bool,
  isRequired: PropTypes.bool,
  validation: PropTypes.object
};

Select.defaultProps = {
  minLength: 2,
  throttle: 350,
  items: [],
  labelKey: 'label',
  multiple: false,
  searchable: true,
  disabled: false,
  valueKey: 'value',
  containerClass: '',
  closeOnChange: true,
  labelText: 'Select value',
  error: undefined,
  allNoneValues: [],
  readOnly: false,
  isMobile: false,
  onChange: null,
  onAutoComplete: null,
  validation: {},
  name: ''
};

export default Select;
