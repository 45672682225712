import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const LazyImg = ({ defaultImg, src, alt, noLazy, className, trigger, ...rest }) => {
  const imageRef = useRef(null);
  useEffect(() => {
    if (!noLazy && 'loading' in HTMLImageElement.prototype) {
      const img = imageRef.current;
      img.src = img.dataset.src;
    }
  }, []);

  if (noLazy) {
    return <img src={src} alt={alt} className={className} {...rest} />;
  }

  const throwError = () => {
    imageRef.current.style.opacity = 0;
    if (trigger) {
      document.dispatchEvent(new CustomEvent('image-loading-error'));
    }
  };

  return (
    <img
      {...rest}
      loading="lazy"
      data-src={src}
      className={'lazyload ' + className}
      alt={alt}
      ref={imageRef}
      onError={() => throwError()}
    />
  );
};

LazyImg.propTypes = {
  defaultImg: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  noLazy: PropTypes.bool,
  className: PropTypes.string,
  trigger: PropTypes.bool
};

LazyImg.defaultProps = {
  alt: '',
  className: ''
};

export default LazyImg;
